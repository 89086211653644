import React from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import moment from 'moment';
import '../../MyTests/styles.scss';
import strings from '../../../utils/strings';
import {updateObject, updateItemInArray} from '../../../utils/reducerUtils';

const genders = [
	{
		label: 'Jente',
		value: 'f',
	},
	{
		label: 'Gutt',
		value: 'm',
	},
	,
	{
		label: 'Annet',
		value: 'u',
	},
];
const yesAndno = [
	{
		label: 'Nei',
		value: 0,
	},
	{
		label: 'Ja',
		value: 1,
	},
	{
		label: 'Vet ikke',
		value: 0,
	},
];

const now = moment();
class NewPayloadModal extends React.Component {
	constructor(props) {
		super(props);
		this.updateTestUser = this.updateTestUser.bind(this);
		this.state = {
			submitEnabled: true,
			isFormValid: false,
			birthDate: moment().subtract(10, 'year').toDate(),
			candidateGrade: null,
			isNorming: 0,
			excludeReading: 0,
			tableRows: null,
			tableValues: null,
			dateIsWrong: false,
			showReadingTestOption: false,
			parsedData: null,
			testUserInfo: {
				firstName: '',
				lastName: '',
				gender: '',
				email: '',
				subscription: '',
				testDevice: '',
				age: {
					years: 0,
					months: 0,
				},
				education: {
					youthGrade: 0,
				},
			},
		};
		this.handleFileChange = this.handleFileChange.bind(this);
	}
	updateTestUser(categoryKey, key, value) {
		const {testUserInfo} = this.state;
		console.log('This is the testUserIhnfo', testUserInfo);
		if (categoryKey) {
			const category = updateObject(testUserInfo[categoryKey], {
				[key]: value,
			});

			this.setState({
				testUserInfo: updateObject(testUserInfo, {
					[categoryKey]: category,
				}),
			});
		} else {
			if (
				key === 'education' &&
				(value.youthGrade == 2 || value.youthGrade == 3)
			) {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
					showReadingTestOption: true,
				});
			} else if (
				key === 'education' &&
				value.youthGrade != 2 &&
				value.youthGrade != 3
			) {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
					showReadingTestOption: false,
					excludeReading: 0,
				});
			} else {
				this.setState({
					testUserInfo: updateObject(testUserInfo, {
						[key]: value,
					}),
				});
			}
		}
	}
	sendCodeAndInfoUsingEmail = (event) => {
		event.preventDefault();
		const {parsedData} = this.state;
		if (parsedData.length) {
			this.props.sendSePayload(parsedData);
		}
	};
	setParsedData(dataFromFile, rowsArray, valuesArray) {
		this.setState({
			parsedData: dataFromFile,
			tableRows: rowsArray[0],
			tableValues: valuesArray,
		});

		console.log('THis is whate we have ', dataFromFile);
		const {parsedData} = this.state;
		/* if (parsedData.length) {
			this.props.sendSePayload(dataFromFile);
		} */
	}
	handleFileChange(event) {}
	render() {
		const {isFormValid, testUserInfo} = this.state;

		let formRef;
		const totalNumbers = [
			{
				id: 1,

				value: 1,
				label: '1',
			},
			{
				id: 2,

				value: 2,
				label: '2',
			},
			{
				id: 3,

				value: 3,
				label: '3',
			},
			,
			{
				id: 4,

				value: 4,
				label: '4',
			},
			{
				id: 5,

				value: 5,
				label: '5',
			},
			,
			{
				id: 6,

				value: 6,
				label: '6',
			},
			,
			{
				id: 7,

				value: 7,
				label: '7',
			},
			,
			{
				id: 8,

				value: 8,
				label: '8',
			},
			,
			{
				id: 9,

				value: 9,
				label: '9',
			},
			,
			{
				id: 10,

				value: 10,
				label: '10',
			},
		];

		const allGrades = [
			{
				id: 6,

				value: 6,
				label: '6. Trinn',
			},

			{
				id: 7,

				value: 7,
				label: '7. Trinn',
			},
			,
			{
				id: 8,

				value: 8,
				label: '8. Trinn',
			},
			,
			{
				id: 9,

				value: 9,
				label: '9. Trinn',
			},
			,
			{
				id: 10,

				value: 10,
				label: '10. Trinn',
			},
		];

		const {testResults} = this.props;
		const {id, key: testKey, testUser} = testResults;

		let firstName;
		let lastName;
		let fullName;

		if (testUser) {
			firstName = testUser.firstName;
			lastName = testUser.lastName;
			fullName = `${firstName} ${lastName}`;
		}

		return (
			<div
				className="lit-modal-candidate"
				onClick={() => {
					this.props.closeWindow();
				}}
			>
				<div
					className="lit-modal__continent"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="lit-candidate-info">
						<div className="lit-candidate-container">
							<div className="lit-candidate-info-header">
								<h3 className="lit-candidate-info-header__bolder">
									{'Create SE payload'}
								</h3>
							</div>
							<form
								className="lit-input-candidate"
								onChange={() => {
									const formValidity = formRef.checkValidity();

									if (
										isFormValid !== formValidity &&
										!this.state.dateIsWrong
									) {
										this.setState({
											isFormValid: formValidity,
										});
									}
								}}
								onSubmit={(e) => {
									e.preventDefault();
									this.sendCodeAndInfoUsingEmail(e);
								}}
								ref={(ref) => {
									formRef = ref;
								}}
							>
								<div className="lit-info__main-container">
									<div className="lit-info__main-container__wrapper">
										<div className="lit-file-upload">
											{/* File Uploader */}
											<label
												style={{color: 'black'}}
												htmlFor="file-upload"
												className="custom-file-upload"
											>
												<input
													type="file"
													name="file"
													id="file-upload"
													accept=".csv"
													onChange={(event) => {
														let dataFromFile = null;
														Papa.parse(
															event.target
																.files[0],
															{
																header: true,
																skipEmptyLines: true,
																complete: (
																	results
																) => {
																	dataFromFile =
																		results.data;
																	console.log(
																		'This is dataFromFile',
																		dataFromFile
																	);

																	const rowsArray = [];
																	const valuesArray = [];

																	// Iterating data to get column name and their values
																	results.data.map(
																		(d) => {
																			rowsArray.push(
																				Object.keys(
																					d
																				)
																			);
																			valuesArray.push(
																				Object.values(
																					d
																				)
																			);
																		}
																	);

																	this.setParsedData(
																		dataFromFile,
																		rowsArray,
																		valuesArray
																	);
																},
															}
														);
													}}
													style={{
														display: 'block',
														marginLeft: '20px',
													}}
												/>
											</label>
										</div>
										<div className="styled-table-main-div">
											{this.state.tableRows &&
												this.state.tableValues && (
													<table className="styled-table">
														<thead>
															<tr>
																{this.state.tableRows.map(
																	(
																		rows,
																		index
																	) => {
																		return (
																			<th
																				key={
																					index
																				}
																			>
																				{
																					rows
																				}
																			</th>
																		);
																	}
																)}
															</tr>
														</thead>
														<tbody>
															{this.state.tableValues.map(
																(
																	value,
																	index
																) => {
																	const classDesign =
																		index %
																			2 ===
																		0
																			? ''
																			: 'active-row';
																	return (
																		<tr
																			className={
																				classDesign
																			}
																			key={
																				index
																			}
																		>
																			{value.map(
																				(
																					val,
																					i
																				) => {
																					return (
																						<td
																							key={
																								i
																							}
																						>
																							{
																								val
																							}
																						</td>
																					);
																				}
																			)}
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
												)}
										</div>
									</div>
								</div>

								<div className="lit-btn-submit-payload-from">
									<button
										className={
											isFormValid
												? 'lit-btn lit-btn bg-primary-glow'
												: 'lit-btn lit-btn bg-primary-dark'
										}
										disabled={!isFormValid}
										onClick={() => {}}
									>
										{'Send'}
									</button>
								</div>
								<br />
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NewPayloadModal;
