import {ENDPOINT_URL} from 'constants/index';
import { apiErrorHandling } from 'utils/apiUtils';
const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function initResetPassword(email) {
	return fetch(`${ENDPOINT_URL}/initresetpassword`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body: JSON.stringify({email}),
	})
		.then((response) => {
			if (!response.ok) {
				return response.json();
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
