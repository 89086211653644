import LocalizedStrings from 'react-localization';
import no from 'languages/no';
import se from 'languages/se';

const strings = new LocalizedStrings({
	no: no,
	se: se,
});

export default strings;
