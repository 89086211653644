import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './styles.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPeopleGroup,
	faPlus,
	faTrash,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import strings from '../../utils/strings';
import {updateObject} from '../../utils/reducerUtils';
import isTrialActive from '../../utils/isTrialActive';
import contract from 'assets/Literate_KundeAvtale.pdf';

import {
	ALL,
	NOT_STARTED,
	DISTRIBUTED,
	STARTED,
	COMPLETED,
	TRIAL,
	BASIC,
	ACTIVE,
	STATUS,
} from 'constants/index';

import NewTestModal from './NewTestModal';
import NewPayloadModal from './NewPayloadModal';
import MyCandidates from './MyCandidates';
import MyClasses from './MyClasses';
import TestRow from './TestRow';
import DumpTests from './DumpTests';
import ViewSeTests from './ViewSeTests';
import CandidateInfo from './CandidateInfo/CandidateInfo';
import AddTestToGroup from './AddTestToGroup';
import InformationPane from '../../components/InformationPane';

import distributeKey from '../../api/distributeKey';
import softDeleteTest from '../../api/softDeleteTest';
import distributeKeyAndStoreInfo from '../../api/distributeKeyAndStoreInfo';
import getCorrespondingGroups from '../../api/getCorrespondingGroups';
import getTestAdminGroupResults from '../../api/getTestAdminGroupResults';
import addCandidatesToClass from '../../api/addCandidatesToClass';
import addClassesToGroup from '../../api/addClassesToGroup';
import getSeTestsForNorming from '../../api/getSeTestsForNorming';
import createSeTests from '../../api/createSeTests';
import createAdminClass from '../../api/createAdminClass';
import addTestToGroup from '../../api/addTestToGroup';
import removeTestsFromGroups from '../../api/removeTestsFromGroups';
import createTests from '../../api/createTests';
import createAndDistributeTests from '../../api/createAndDistributeTests';
import createBulkTests from '../../api/createBulkTests';

import getTestsForDump from '../../api/getTestsForDump';

import deleteClassMembers from '../../api/deleteClassMembers';
import deleteAllTestsPermanently from '../../api/deleteAllTestsPermanently';
import deleteSingleTestPermanently from '../../api/deleteSingleTestPermanently';
import getFilteredTestResults from '../../utils/getFilteredTestResults';
import getAllClasses from '../../api/getAllClasses';
import getBatchedTestResults from '../../utils/getBatchedTestResults';
import addMomentsToTestResults from '../../utils/addMomentsToTestResults';
import getTestAdminTestResults from '../../api/getTestAdminTestResults';
import getTestUsersForTestAdmin from '../../api/getTestUsersForTestAdmin';
import renameClass from '../../api/renameClass';
import restartTest from '../../api/restartTest';
import TrashCanModel from './TrashCanModel';
const statusFilters = [
	STATUS,
	ALL,
	NOT_STARTED,
	DISTRIBUTED,
	STARTED,
	COMPLETED,
];

const allMyTests = strings.allmytests;
const defaultExistingKeysForGroup = [];
const batchTestResult = (testResults) => {
	const testResultsWithStatus = testResults.map((testResult) => {
		let status;
		if (testResult.completedAt) {
			status = COMPLETED;
		} else if (testResult.usedAt) {
			status = STARTED;
		} else if (testResult.distributedAt) {
			status = DISTRIBUTED;
		} else {
			status = NOT_STARTED;
		}

		return {...testResult, status};
	});

	const testResultsWithMoments = addMomentsToTestResults(
		testResultsWithStatus
	);

	return getBatchedTestResults(testResultsWithMoments);
};
const processTestResults = (testResults, recentKey) => {
	const testResultsWithStatus = testResults.map((testResult) => {
		let status;
		if (testResult.completedAt) {
			status = COMPLETED;
		} else if (testResult.usedAt) {
			status = STARTED;
		} else if (testResult.distributedAt) {
			status = DISTRIBUTED;
		} else {
			status = NOT_STARTED;
		}

		return updateObject(testResult, {
			status,
		});
	});

	const testResultsWithMoments = addMomentsToTestResults(
		testResultsWithStatus
	);

	const batchedTestResults = getBatchedTestResults(testResultsWithMoments);
	return batchedTestResults;
};

class MyTests extends React.Component {
	constructor() {
		super();

		this.monthRefs = {};

		this.state = {
			activeFilter: ALL,
			isAddingNewTest: false,
			isAddingTestToGroup: false,
			batchedTestResults: null,
			testResults: [],
			selectedGroup: '',
			groupsobject: [],
			groups: [],
			isGroupPanelActive: false,
			groupTests: [],
			grouptests: false,
			adminClasses: [],
			classData: [],
			showPricingInfo: false,
			subscription_id: null,
			userLanguages: null,
			seTestsForNorming: [],
			isDumpingTests: false,
			isViewingSeTests: false,
			showMyTests: true,
			showMyCandidates: false,
			showMyClasses: false,
			showTestActive: 'tablink1-active',
			showCandidatesactive: 'tablink2',
			showClassesActive: 'tablink3',
			averageZscore: [],
			testUsers: [],
			batchedFilteredTestResults: null,
			existingKeysForGroup: defaultExistingKeysForGroup,
			groupBatchedTestResults: null,
			animator: 'marker-default',
			showInfoPanel: false,
			infoPanelTestKey: null,
			initiatesRedistribution: false,
			paneVariable: null,
			paneMessage: '',
			initiateSoftDeletion: false,
			renderPaneMessage: false,
			groupedOption: [],
			openTrashCanModel: false,
			deletedTests: [],
			isCandidateDataFirstTimeRendered: true,
			isSendingPayload: false,
		};

		/* this.getTestsReadyForDump = this.getTestsReadyForDump.bind(this); */

		this.getAndSetTestAdminTestResults = this.getAndSetTestAdminTestResults.bind(
			this
		);
		this.getAndsetTestAdminTestUsers = this.getAndsetTestAdminTestUsers.bind(
			this
		);
		/* this.getCorrespondingGroups = this.getCorrespondingGroups.bind(this); */
		this.getAndSetTestAdminGroupTestResults = this.getAndSetTestAdminGroupTestResults.bind(
			this
		);
		this.renderCandidateInfoPanel = this.renderCandidateInfoPanel.bind(
			this
		);
		this.setStateWithBatchedFilteredResults = this.setStateWithBatchedFilteredResults.bind(
			this
		);
		/* 
		this.getAndSetUserClasses = this.getAndSetUserClasses.bind(this); */
		this.setUserLanguages = this.setUserLanguages.bind(this);
		this.determineValidSubscription = this.determineValidSubscription.bind(
			this
		);

		/* this.setLoaderToFalse = this.setLoaderToFalse.bind(this); */
		this.revertIsCandidateDataFirstTimeRendered = this.revertIsCandidateDataFirstTimeRendered.bind(
			this
		);

		/* this.dumpSingleTest = this.dumpSingleTest.bind(this); */
	}

	componentDidMount() {
		this.getAndSetTestAdminTestResults().then(() => {
			// if (isFinite(year) && isFinite(month)) {
			// 	const monthBatchKey = `${year}${month}`;
			// 	const monthRef = this.monthRefs[monthBatchKey];
			// 	if (monthRef) {
			// 		monthRef.scrollIntoView(true);
			// 	}
			// }
		});
		this.getAndSetGroupMenu();
		this.getAndSeTestsForNorming();
		// this.getAndsetTestAdminTestUsers();
		this.getAndSetAdminClasses();
		//window.addEventListener('focus', this.getAndSetTestAdminTestResults);
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.getAndSetTestAdminTestResults);
	}
	reloadPage(time) {
		setTimeout(function () {
			window.location.reload();
		}, time);
	}

	determineValidSubscription(languages) {
		const searchAbaleSubscriptionFields = [
			'child',
			'youth',
			'adult',
			'norming_child',
			'adult_followup',
			'child_followup',
			'youth_followup',
			'norming_youth',
		];
		for (let i = 0; i < languages.length; i++) {
			for (const field of searchAbaleSubscriptionFields) {
				if (languages[i][field] !== 0) {
					return false;
				}
			}
		}
		return true;
	}
	revertIsCandidateDataFirstTimeRendered() {
		this.setState({isCandidateDataFirstTimeRendered: false});
	}

	getAndSeTestsForNorming() {
		const {currentUser} = this.props;
		if (currentUser.isSuperAdmin) {
			return getSeTestsForNorming().then((results) => {
				const tests = results;

				return new Promise((resolve) => {
					if (tests.length) {
						this.setState(
							{
								seTestsForNorming: tests,
							},
							resolve
						);
					} else {
						this.setState(
							{
								seTestsForNorming: [],
							},
							resolve
						);
					}
				});
			});
		}
	}

	sendCodeUsingEmail = (id, testKey, emailAddress) => {
		distributeKey(testKey, emailAddress, 'swedish')
			.then((res) => {
				if (res.message) {
					this.setState({
						initiatesRedistribution: false,
						paneMessage: 'Testet har nu skickats till kandidaten',
						renderPaneMessage: true,
					});
				}
			})
			.then(() => {
				const {currentUser} = this.props;
				const id = currentUser.id;
				return getTestAdminTestResults(id).then((returnArray) => {
					const {testResults = [], deletedKeys = []} = returnArray;

					let batchedFilteredTestResults;
					const batchedTestResults = processTestResults(
						testResults,
						testKey
					);

					if (batchedTestResults) {
						batchedFilteredTestResults = getFilteredTestResults(
							batchedTestResults,
							this.state.activeFilter
						);
					}

					return new Promise((resolve) => {
						this.setState(
							{
								testResults,
								batchedTestResults,
								showInfoPanel: false,
								batchedFilteredTestResults: batchedFilteredTestResults,
								deletedTests: deletedKeys,
								openTrashCanModel: !deletedKeys.length
									? false
									: this.state.openTrashCanModel,
							},
							resolve
						);
					});
				});
			});
	};
	continueTest(id, testKey) {
		this.setState({
			initiatesRedistribution: true,
			paneVariable: {id, testKey},
			paneMessage: strings.paneMessageContinueTest,
		});
	}
	redistributeContinuedTest(paneVariables) {
		if (paneVariables) {
			restartTest(paneVariables.id, paneVariables.testKey).then((res) => {
				if (res.message) {
					this.setState({
						initiatesRedistribution: false,
						paneMessage: strings.formatString(
							strings.testredistributed,
							<b>{`${res.pinCode}`}</b>
						),
						renderPaneMessage: true,
					});
					const {currentUser} = this.props;
					const id = currentUser.id;

					return getTestAdminTestResults(id).then((returnArray) => {
						const {
							testResults = [],
							deletedKeys = [],
						} = returnArray;

						let batchedFilteredTestResults;
						const batchedTestResults = processTestResults(
							testResults,
							null
						);
						if (batchedTestResults) {
							batchedFilteredTestResults = getFilteredTestResults(
								batchedTestResults,
								this.state.activeFilter
							);
						}

						return new Promise((resolve) => {
							this.setState(
								{
									testResults,
									batchedTestResults,
									showInfoPanel: false,
									batchedFilteredTestResults: batchedFilteredTestResults,
									deletedTests: deletedKeys,
									openTrashCanModel: !deletedKeys.length
										? false
										: this.state.openTrashCanModel,
								},
								resolve
							);
						});
					});
				}
			});
		}
	}

	renderStaticMessagePane(message) {
		this.setState({
			paneMessage: message,
			renderPaneMessage: true,
		});
	}
	renderDynamicMessagePane(message, id, key) {
		this.setState({
			initiateSoftDeletion: true,
			paneVariable: {id, key},
			paneMessage: message,
		});
	}

	softDeleteTest = (returnedProps) => {
		const id = returnedProps.id;
		const testKey = returnedProps.testKey;
		/* e.preventDefault(); */

		softDeleteTest(id, testKey).then(() => {
			this.setState({
				initiateSoftDeletion: false,
				paneMessage: 'Testen er slettet',
				renderPaneMessage: true,
			});
			const {currentUser} = this.props;
			const id = currentUser.id;

			return getTestAdminTestResults(id).then((returnArray) => {
				const {testResults = [], deletedKeys = []} = returnArray;

				let batchedFilteredTestResults;
				const batchedTestResults = processTestResults(
					testResults,
					testKey
				);
				if (batchedTestResults) {
					batchedFilteredTestResults = getFilteredTestResults(
						batchedTestResults,
						this.state.activeFilter
					);
				}

				return new Promise((resolve) => {
					this.setState(
						{
							testResults,
							batchedTestResults,
							showInfoPanel: false,
							batchedFilteredTestResults: batchedFilteredTestResults,
							deletedTests: deletedKeys,
							openTrashCanModel: !deletedKeys.length
								? false
								: this.state.openTrashCanModel,
						},
						resolve
					);
				});
			});
		});
	};
	renderCandidateInfoPanel(testKey, id, pinCode) {
		this.setState({
			showInfoPanel: true,
			infoPanelTestKey: {testKey: testKey, id: id, pinCode: pinCode},
		});
	}
	sendAndSaveSePayload(testUser) {
		if (!testUser) {
			return;
		}
		createSeTests(testUser).then(() => {
			this.setState({
				isSendingPayload: false,
			});
		});
	}
	distributeTestWithInfo(
		isNorming,
		testKey,
		testUser,
		excludeReading,
		pinCode
	) {
		if (!testKey || !testUser) {
			return;
		}

		distributeKeyAndStoreInfo(
			isNorming,
			testKey,
			testUser,
			excludeReading,
			pinCode
		)
			.then((res) => {
				if (res.message) {
					const msgText =
						pinCode && pinCode !== 'undefined'
							? `Informasjonen til kandidaten er lagret. Testen er klar og kan startes med pinkoden ${pinCode}`
							: `Informasjonen til kandidaten er lagret. Testen er klar og kan startes med pinkoden.`;
					this.setState({
						initiatesRedistribution: false,
						paneMessage: msgText,
						renderPaneMessage: true,
					});
				}
			})
			.then(() => {
				const {currentUser} = this.props;
				const id = currentUser.id;
				return getTestAdminTestResults(id).then((returnArray) => {
					const {testResults = [], deletedKeys = []} = returnArray;
					let batchedFilteredTestResults;
					const batchedTestResults = processTestResults(
						testResults,
						testKey
					);

					if (batchedTestResults) {
						batchedFilteredTestResults = getFilteredTestResults(
							batchedTestResults,
							this.state.activeFilter
						);
					}

					return new Promise((resolve) => {
						this.setState(
							{
								testResults,
								batchedTestResults,
								showInfoPanel: false,
								batchedFilteredTestResults: batchedFilteredTestResults,
								deletedTests: deletedKeys,
								openTrashCanModel: !deletedKeys.length
									? false
									: this.state.openTrashCanModel,
							},
							resolve
						);
					});
				});
			});
	}
	assignCandidatesToClass(testCandidates) {
		if (testCandidates.length) {
			addCandidatesToClass(testCandidates).then(() => {
				this.getAndSetAdminClasses();
				this.setState({
					paneMessage: strings.testcandidateadded,
					renderPaneMessage: true,
					animator: 'marker-myclasses',
					showClassesActive: 'tablink3-active',
					showTestActive: 'tablink1',
					showCandidatesactive: 'tablink2',
					showMyTests: false,
					showMyCandidates: false,
					showMyClasses: true,
				});
			});
		}
	}
	deleteSelectedClassMembers(testCandidates, targetClass, subsid) {
		deleteClassMembers(testCandidates, targetClass, subsid).then(() => {
			this.getAndSetAdminClasses();

			this.setState({
				paneMessage: strings.testcandidateremoved,
				renderPaneMessage: true,
				animator: 'marker-myclasses',
				showClassesActive: 'tablink3-active',
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2',
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
			});
		});
	}

	createNewAdminClass(usersIds, adminId, subsId, className, groupId) {
		if (!usersIds || !adminId || !subsId || !className) {
			alert(strings.message_incompletData);
			return;
		}
		createAdminClass(usersIds, adminId, subsId, className, groupId).then(
			(res) => {
				if (!res.error) {
					this.setState({
						paneMessage: 'Ny Klasse opprettet',
						renderPaneMessage: true,
					});
					this.getAndSetAdminClasses();
					this.setState({
						animator: 'marker-myclasses',
						showClassesActive: 'tablink3-active',
						showTestActive: 'tablink1',
						showCandidatesactive: 'tablink2',
						showMyTests: false,
						showMyCandidates: false,
						showMyClasses: true,
					});
				}
				/* this.setState({
				isCreatingGroup: false,
			}); */
			}
		);
	}

	async isRenamingClass(info) {
		await renameClass(info);
		this.getAndSetAdminClasses();
		this.getAndSetGroupMenu();
	}

	getAndSetGroupMenu() {
		const {currentUser} = this.props;
		const id = currentUser.id;

		return getCorrespondingGroups(id).then((results) => {
			const groupsobject = results.allGroups;
			const groupedOption = results.groupedOptions;

			return new Promise((resolve) => {
				if (groupsobject.length) {
					const groups = groupsobject.map((groups) => {
						return groups.name;
					});

					this.setState(
						{
							groups,
							selectedGroup: groupsobject[0].id,
							groupsobject,
							groupedOption,
						},
						resolve
					);
				} else {
					this.setState(
						{
							groups: [],
							selectedGroup: '',
						},
						resolve
					);
				}
			});
		});
	}

	async getAndSetTestAdminGroupTestResults(selectedGroup) {
		const selectedgroup = selectedGroup;
		if (selectedGroup !== '') {
			const testData = await getTestAdminGroupResults(selectedgroup);
			const {testResults = []} = testData;

			let batchedFilteredTestResults;
			const batchedTestResults = await processTestResults(
				testResults,
				null
			);
			if (batchedTestResults) {
				batchedFilteredTestResults = getFilteredTestResults(
					batchedTestResults,
					this.state.activeFilter
				);
			}
			return await new Promise((resolve) => {
				this.setState(
					{
						testResults,
						batchedTestResults,
						batchedFilteredTestResults: batchedFilteredTestResults,
					},
					resolve
				);
			});
		}
		return null;
	}
	getAndSetTestAdminTestResults() {
		const {currentUser} = this.props;
		const id = currentUser.id;

		return getTestAdminTestResults(id).then((returnArray) => {
			const {testResults = [], deletedKeys = []} = returnArray;
			let batchedFilteredTestResults;
			const batchedTestResults = processTestResults(testResults, null);
			if (batchedTestResults) {
				batchedFilteredTestResults = getFilteredTestResults(
					batchedTestResults,
					this.state.activeFilter
				);
			}

			return new Promise((resolve) => {
				this.setState(
					{
						testResults,
						batchedTestResults,
						showInfoPanel: false,
						batchedFilteredTestResults: batchedFilteredTestResults,
						deletedTests: deletedKeys,
						openTrashCanModel: !deletedKeys.length
							? false
							: this.state.openTrashCanModel,
					},
					resolve
				);
			});
		});
	}

	getAndsetTestAdminTestUsers() {
		return getTestUsersForTestAdmin().then((testUsers) => {
			return new Promise((resolve) => {
				this.setState(
					{
						testUsers,
					},
					resolve
				);
			});
		});
	}
	getAndSetAdminClasses() {
		const {currentUser} = this.props;
		const id = currentUser.id;
		getAllClasses().then((classData) => {
			const adminClasses = classData.map((usersClass) => ({
				className: usersClass.className,
				classId: usersClass.classId,
			}));

			this.setState({
				classData,
				adminClasses,
			});
		});
	}

	setUserLanguages(user) {
		const languages = user.subscription.languages;
		const finalProduct = [];
		const allLanguages = [];
		for (let i = 0; i < languages.length; i++) {
			const languageKey = languages[i].language;
			allLanguages[languageKey] = languages[i];
		}
		finalProduct.push(allLanguages);
		this.setState({userLanguages: finalProduct});
	}

	toggleShowPricingInfo() {
		this.setState({showPricingInfo: !this.state.showPricingInfo});
	}
	validateLanguageSubscription(selectedLanguage, testkeyTypes) {
		const {currentUser} = this.props;
		const languages = currentUser.subscription.languages;
		const subscriptionFields = [
			'child',
			'youth',
			'adult',
			'child_followup',
			'youth_followup',
			'adult_followup',
			'norming_child',
			'norming_youth',
			'norming_adult',
			'basic',
		];
		let flag = 0;
		for (let i = 0; i < languages.length; i++) {
			if (languages[i].language === selectedLanguage) {
				for (let j = 0; j < testkeyTypes.length; j++) {
					for (const field of subscriptionFields) {
						if (languages[i][field] === 1) {
							if (
								field === 'child' &&
								testkeyTypes[j].type === 'child'
							) {
								flag = flag + 1;
							}
							if (
								field === 'youth' &&
								testkeyTypes[j].type === 'youth-screening'
							) {
								flag = flag + 1;
							}
							if (
								field === 'adult' &&
								testkeyTypes[j].type === 'adult-screening'
							) {
								flag = flag + 1;
							}
							if (
								field === 'youth_followup' &&
								testkeyTypes[j].type === 'youth-followup'
							) {
								flag = flag + 1;
							}
							if (
								field === 'adult_followup' &&
								testkeyTypes[j].type === 'adult-followup'
							) {
								flag = flag + 1;
							}
						}
					}
				}
				break;
			}
		}
		if (flag === testkeyTypes.length) {
			return true;
		}
		return false;
	}
	getTestKey(selectedKey) {
		this.setState({
			existingKeysForGroup: [
				...this.state.existingKeysForGroup,
				{key: selectedKey},
			],
		});
	}
	removeTestKey(selectedKey) {
		const allTestKeys = this.state.existingKeysForGroup;
		const indexVal = this.findWithProp(allTestKeys, 'key', selectedKey);

		if (indexVal > -1) {
			allTestKeys.splice(indexVal, 1);
			this.setState({
				existingKeysForGroup: allTestKeys,
			});
		}
	}
	findWithProp(array, attr, value) {
		for (let i = 0; i < array.length; i += 1) {
			if (array[i][attr] === value) {
				return i;
			}
		}
		return -1;
	}
	addingTestToGroup(groupId, selectedTests) {
		for (let i = 0; i < selectedTests.length; i++) {
			selectedTests[i].groupId = groupId;
		}

		addTestToGroup(selectedTests).then(() => {
			const alertMessage = strings.formatString(
				strings.selectedTestAddedToGroup,
				`${selectedTests.length > 1 ? 'tester' : 'test'}`
			);
			this.getAndSetTestAdminTestResults();

			this.setState({
				isAddingTestToGroup: false,
				existingKeysForGroup: [],
				paneMessage: alertMessage,
				renderPaneMessage: true,
			});
			this.setState({
				showMyTests: false,
				showMyCandidates: true,
				showMyClasses: false,
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2-active',
				showClassesActive: 'tablink3',
			});
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
		});
	}
	removingTestsFromGroups(selectedTests) {
		removeTestsFromGroups(selectedTests).then(() => {
			const alertMessage = strings.formatString(
				strings.selectedTestRemovedFromGroup,
				`${selectedTests.length > 1 ? 'tester' : 'test'}`
			);
			this.getAndSetTestAdminTestResults();
			this.setState({
				isAddingTestToGroup: false,
				existingKeysForGroup: [],
				paneMessage: alertMessage,
				renderPaneMessage: true,
			});
		});
	}
	renderMonth(monthBatch, index) {
		const {testResults} = this.state;

		if (!monthBatch.testResults.length) {
			return null;
		}

		return (
			<div
				className="month"
				key={monthBatch.key}
				ref={(ref) => {
					this.monthRefs[monthBatch.key] = ref;
				}}
			>
				{/* {monthBatch.length === 0 && <p>{strings.nogroupsfound}</p>} */}
				<h3 className="month__heading">{monthBatch.name}</h3>
				<table
					className={
						index === 0 ? 'lit-first-test-table' : 'lit-tests'
					}
				>
					<thead className="lit-tests-thead">
						<tr>
							<td />

							{!this.state.isGroupPanelActive && (
								<td className="lit-test__header">
									{strings.status}
								</td>
							)}
							{this.state.isGroupPanelActive && (
								<td className="lit-test__header">
									{strings.status}
								</td>
							)}

							<td className="lit-test__header">
								{strings.testkey}
							</td>
							<td className="lit-test__header">{strings.type}</td>
							<td className="lit-test__header">
								{strings.recipient}
							</td>

							<td className="lit-test__header">
								{strings.actions}
							</td>
						</tr>
					</thead>
					<tbody>
						{monthBatch.testResults.map((testResult) => (
							<TestRow
								key={testResult.id}
								processTestResults={processTestResults}
								testResult={testResult}
								testResults={testResults}
								onGettingTestKey={(selectedKey) => {
									return this.getTestKey(selectedKey); //removeTestKey
								}}
								onRemovingTestKey={(selectedKey) => {
									return this.removeTestKey(selectedKey); //removeTestKey
								}}
								groupPanel={this.state.isGroupPanelActive}
								onDelete={this.getAndSetTestAdminTestResults}
								onSoftDelete={
									this.getAndSetTestAdminTestResults
								}
								isSuperAdmin={
									this.props.currentUser.isSuperAdmin
								}
								onDistributeTest={(id, key, email) => {
									this.sendCodeUsingEmail(id, key, email);
								}}
								onStartTest={this.getAndSetTestAdminTestResults}
								onContinueTest={(e, id, testKey) => {
									this.continueTest(id, testKey);
								}}
								onshowInfoPanel={(testKey, id, pinCode) => {
									this.renderCandidateInfoPanel(
										testKey,
										id,
										pinCode
									);
								}}
								onRenderStaticMessagePane={(message) => {
									this.renderStaticMessagePane(message);
								}}
								onRenderDynamicMessagePane={(
									message,
									id,
									key
								) => {
									this.renderDynamicMessagePane(
										message,
										id,
										key
									);
								}}
							/>
						))}
					</tbody>
				</table>
			</div>
		);
	}

	onSaveNewTest = (
		type,
		newTestCount,
		groupId,
		language,
		anamnese,
		childSelfStarting,
		candidateEmails
	) => {
		if (!type) {
			this.setState({
				isAddingNewTest: false,
			});
			return;
		}

		if (!newTestCount) {
			this.setState({
				isAddingNewTest: false,
			});
			return;
		}
		if (!language) {
			this.setState({
				isAddingNewTest: false,
			});
			return;
		}

		const newTestCountInt = parseInt(newTestCount, 10);

		if (!isFinite(newTestCountInt)) {
			return;
		}
		if (candidateEmails) {
			createAndDistributeTests(
				type,
				newTestCountInt,
				groupId,
				language,
				anamnese,
				childSelfStarting,
				candidateEmails
			).then(() => {
				this.getAndSetTestAdminTestResults();
				this.setState({
					isAddingNewTest: false,
				});
				return true;
			});
		} else {
			createTests(
				type,
				newTestCountInt,
				groupId,
				'swedish',
				anamnese,
				childSelfStarting
			)
				.then(() => {
					this.getAndSetTestAdminTestResults();
					return true;
				})
				.then(() => {
					this.setState({
						isAddingNewTest: false,
					});
				});
		}
	};
	setStateWithBatchedFilteredResults(results) {
		this.setState({getBatchedTestResults: results});
	}
	onSaveNewBulkTest = (
		type,
		newTestCount,
		groupId,
		language,
		testUsers,
		testkeyTypes,
		childSelfStarting
	) => {
		if (!type) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}

		if (newTestCount !== 1) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}
		if (!language) {
			this.setState({
				showMyTests: true,
				showMyCandidates: false,
				showMyClasses: false,
				showTestActive: 'tablink1-active',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3',
			});
			return;
		}

		const newTestCountInt = parseInt(testkeyTypes.length, 10);
		if (!isFinite(newTestCountInt)) {
			return;
		}
		createBulkTests(
			testkeyTypes,
			newTestCountInt,
			groupId,
			language,
			type,
			childSelfStarting
		)
			.then(() => {
				this.getAndSetTestAdminTestResults();
				return true;
			})
			.then(() => {
				this.setState({
					showMyTests: true,
					showMyCandidates: false,
					showMyClasses: false,
					showTestActive: 'tablink1-active',
					showCandidatesactive: 'tablink2',
					showClassesActive: 'tablink3',
					animator: 'marker-default',
				});
			});
	};

	onSelectGroup = (e) => {
		if (e.target.value === allMyTests) {
			this.getAndSetTestAdminTestResults();
			this.setState({
				isGroupPanelActive: false,
			});
		} else {
			const chosenGroup = this.state.groupsobject.filter(
				(groupname) => groupname.name === e.target.value
			);
			const activegroup = chosenGroup[0].id;
			this.getAndSetTestAdminGroupTestResults(activegroup);

			this.setState({
				selectedGroup: chosenGroup[0].id,
				isGroupPanelActive: true,
			});
		}
	};

	onSelectStatus = (e) => {
		this.setState(
			{
				activeFilter: statusFilters.filter(
					(filter) => filter.id === e.target.value
				)[0],
			},
			() => {
				let batchedFilteredTestResults;
				const batchedTestResults = processTestResults(
					this.state.testResults,
					null
				);
				if (batchedTestResults) {
					batchedFilteredTestResults = getFilteredTestResults(
						batchedTestResults,
						this.state.activeFilter
					);
				}

				return new Promise((resolve) => {
					this.setState(
						{
							batchedTestResults,
							showInfoPanel: false,
							batchedFilteredTestResults: batchedFilteredTestResults,
						},
						resolve
					);
				});
			}
		);
	};

	addSelectedClassesToGroup(classIds, groupIds) {
		const groupedClasses = {classIds, groupIds};
		if (!classIds || !groupIds) {
			return;
		}
		addClassesToGroup(groupedClasses).then((res) => {
			this.setState({
				paneMessage: ' kandidater lagt til i gruppen',
				renderPaneMessage: true,
			});
			this.getAndSetAdminClasses();
			this.setState({
				showMyTests: false,
				showMyCandidates: false,
				showMyClasses: true,
				showTestActive: 'tablink1',
				showCandidatesactive: 'tablink2',
				showClassesActive: 'tablink3-active',
			});
		});
	}
	renderUpgradeBox(isBasicUser, isTrialUser, trialIsActive) {
		return (
			(isTrialUser || isBasicUser) && (
				<div className="lit-new-subscription">
					{isTrialUser && !trialIsActive && (
						<h3 className="lit-new-subscription__header">
							{strings.yourtrialperiodhasexpired}
						</h3>
					)}
					{(isBasicUser || (isTrialUser && trialIsActive)) && (
						<h3 className="lit-new-subscription__header">
							{strings.upgradeyoursubscription}
						</h3>
					)}
					<p className="lit-new-subscription__description">
						{strings.downloadandsendcontract}
						<a
							href="mailto:post@dysmate.no"
							target="_blank"
							rel="noopener noreferrer"
						>
							{`admin@dysmate.se`}
						</a>
						{strings.priceandconditioninfo}
						<a
							href="https://dysmate.se/pris-og-kjopsbetingelser/"
							target="_blank"
							rel="noopener noreferrer"
						>
							{`https://dysmate.se/pris-og-kjopsbetingelser/`}
						</a>
					</p>
					<a
						className="lit-btn bg-primary"
						download="Literate_KundeAvtale.pdf"
						href={contract}
					>
						{strings.downloadcontract}
					</a>
				</div>
			)
		);
	}

	render() {
		const {currentUser} = this.props;
		const languages = currentUser.subscription.languages;
		const hasInValidSubscription = this.determineValidSubscription(
			languages
		);

		const {userStage} = currentUser;
		const {
			activeFilter,
			isAddingNewTest,
			seTestsForNorming,
			batchedTestResults,
			userLanguages,
			isDumpingTests,
			classData,
			isSendingPayload,
			classes,
			isViewingSeTests,
			openTrashCanModel,
			deletedTests,
			isCandidateDataFirstTimeRendered,
		} = this.state;
		/* let batchedFilteredTestResults;
		if (batchedTestResults) {
			batchedFilteredTestResults = getFilteredTestResults(
				batchedTestResults,
				activeFilter
			);
			this.setStateWithBatchedFilteredResults(batchedFilteredTestResults);
		} */

		const trialIsActive = isTrialActive(currentUser);
		const isBasicUser = userStage === BASIC;
		const isTrialUser = userStage === TRIAL;
		const isActiveUser = userStage === ACTIVE;
		return (
			<div className="lit-tests-view">
				<nav>
					<div id={this.state.animator}></div>
					<a
						href="#"
						/* className={this.state.showTestActive} */
						id="defaultOpen"
						onClick={() => {
							this.setState({
								showMyTests: true,
								showMyCandidates: false,
								showMyClasses: false,
								showTestActive: 'tablink1-active',
								showCandidatesactive: 'tablink2',
								showClassesActive: 'tablink3',
								existingKeysForGroup: defaultExistingKeysForGroup,
								animator: 'marker-default',
							});
							this.getAndSetTestAdminTestResults();
						}}
					>
						Mina Tester
					</a>
					<a
						href="#"
						/* className={this.state.showCandidatesactive} */
						onClick={() => {
							if (isCandidateDataFirstTimeRendered) {
								this.getAndsetTestAdminTestUsers();
							}
							this.setUserLanguages(currentUser);
							this.setState({
								showMyTests: false,
								showMyCandidates: true,
								showMyClasses: false,
								showCandidatesactive: 'tablink2-active',
								showTestActive: 'tablink1',
								showClassesActive: 'tablink3',
								animator: 'marker-mycandidates',
							});
						}}
					>
						Mina Kandidater
					</a>
					<a
						href="#"
						/* className={this.state.showClassesActive} */
						onClick={() => {
							this.setState({
								showClassesActive: 'tablink3-active',
								showTestActive: 'tablink1',
								showCandidatesactive: 'tablink2',
								showMyTests: false,
								showMyCandidates: false,
								showMyClasses: true,
								animator: 'marker-myclasses',
							});
						}}
					>
						Mina Klasser
					</a>
				</nav>

				{/*scrollers starting div*/}
				{this.state.showInfoPanel && (
					<CandidateInfo
						closeWindow={() => {
							this.setState({
								showInfoPanel: false,
							});
						}}
						testResults={this.state.testResults}
						processTestResults={processTestResults}
						infoPanelTestKey={this.state.infoPanelTestKey}
						onDistributeTest={this.getAndSetTestAdminTestResults}
						onDistributeKeyAndStoreInfo={(
							isNorming,
							testKey,
							testUser,
							excludeReading,
							pinCode
						) => {
							this.distributeTestWithInfo(
								isNorming,
								testKey,
								testUser,
								excludeReading,
								pinCode
							);
						}}
					/>
				)}
				{this.state.renderPaneMessage && (
					<InformationPane
						paneMessage={this.state.paneMessage}
						onApproval={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						onCloseWindow={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						onDisproval={() => {
							this.setState({
								renderPaneMessage: false,
							});
						}}
						category={'information'}
					/>
				)}
				{this.state.showMyTests && (
					<div className="lit-tests-view__header">
						<div className="lit-new-test__wrapper">
							{isTrialUser && (
								<p>{strings.onlytwotestspertrialperiod}</p>
							)}
							{isBasicUser && (
								<p>{strings.upgradetofullsubscription}</p>
							)}
							<div className="lit-input-tab-grid">
								<button
									className="lit-mytests-header-mytest-btn-primary"
									disabled={
										hasInValidSubscription ||
										isBasicUser ||
										isTrialUser
									}
									onClick={() => {
										this.setUserLanguages(currentUser);
										this.setState({
											isAddingNewTest: true,
											grouptests: false,
										});
									}}
								>
									<FontAwesomeIcon
										className="lit-icon-btns"
										disabled={
											hasInValidSubscription ||
											isBasicUser ||
											isTrialUser
										}
										icon={faPlus}
									/>
									<p className="lit-btns-txt">
										{strings.createnewtest}
									</p>
								</button>

								{Boolean(this.state.groups.length) && (
									<button
										className="lit-mytests-header-mytest-btn-secondary"
										onClick={() => {
											this.setUserLanguages(currentUser);
											this.setState({
												isAddingTestToGroup: true,
												grouptests: false,
											});
										}}
										disabled={
											hasInValidSubscription ||
											Boolean(
												!this.state.groups.length
											) ||
											Boolean(
												!this.state.existingKeysForGroup
													.length
											)
										}
									>
										<FontAwesomeIcon
											className="lit-icon-btns"
											icon={faPeopleGroup}
										/>
										<p className="lit-btns-txt">
											{strings.addtesttogroup}
										</p>
									</button>
								)}

								{currentUser.isSuperAdmin &&
									!this.state.isGroupPanelActive && (
										<button
											className="lit-mytests-header-mytest-btn-ternary"
											onClick={() => {
												this.setState({
													isDumpingTests: true,
												});
											}}
										>
											<FontAwesomeIcon
												className="lit-icon-btns"
												icon={faXmark}
											/>
											<p className="lit-btns-txt">
												{strings.deleteoldtests}
											</p>
										</button>
									)}
								<button
									className={
										'lit-mytests-header-mytest-btn-ternary2'
									}
									onClick={() => {
										this.setState({
											openTrashCanModel: true,
										});
									}}
								>
									<FontAwesomeIcon
										className="lit-icon-btns"
										icon={faTrash}
									/>
									<p className="lit-btns-txt">
										{strings.trashcan}
									</p>
								</button>

								{currentUser.isSuperAdmin && (
									<button
										className="lit-btn bg-primary"
										style={{
											marginLeft: '10px',
											marginRight: '10px',
											borderRadius: '20px',
										}}
										disabled={
											hasInValidSubscription ||
											isBasicUser ||
											isTrialUser
										}
										onClick={() => {
											this.setUserLanguages(currentUser);
											this.setState({
												isSendingPayload: true,
												grouptests: false,
											});
										}}
									>
										{'send payload'}
									</button>
								)}
								{currentUser.isSuperAdmin &&
									!this.state.isGroupPanelActive &&
									Boolean(seTestsForNorming.length) && (
										<button
											className="lit-btn bg-primary"
											style={{
												marginLeft: '10px',
												marginRight: '10px',
												borderRadius: '20px',
												backgroundColor: 'cyan',
											}}
											disabled={!seTestsForNorming.length}
											onClick={() => {
												this.setState({
													isViewingSeTests: true,
												});
											}}
										>
											{'Sweidsh TestKeys '}
										</button>
									)}
								<div className="lit-View_tests">
									<div className="lit-dropdown_filter">
										<select
											className="lit-input__field"
											onChange={(e) => {
												this.onSelectStatus(e);
											}}
										>
											{statusFilters.map((filters) => (
												<option
													key={filters.id}
													value={filters.id}
													disabled={
														filters.label ===
														strings.status
													}
												>
													{filters.label}
												</option>
											))}
										</select>
									</div>

									{Boolean(this.state.groups.length) && (
										<div className="Select_group-function">
											<select
												className="lit-input__field"
												defaultValue={allMyTests}
												onChange={(e) => {
													if (
														e.target.value ===
														allMyTests
													) {
														this.getAndSetTestAdminTestResults();
														this.setState({
															isGroupPanelActive: false,
														});
													} else {
														const chosenGroup = this.state.groupsobject.filter(
															(groupname) =>
																groupname.name ===
																e.target.value
														);

														const activegroup =
															chosenGroup[0].id;
														this.getAndSetTestAdminGroupTestResults(
															activegroup
														);

														this.setState({
															selectedGroup:
																chosenGroup[0]
																	.id,
															isGroupPanelActive: true,
														});
													}
												}}
											>
												<option
													className="label_grupper-option"
													disabled="disabled"
												>
													{strings.groupindicator}
												</option>
												<option
													key={1}
													value={allMyTests}
												>
													{allMyTests}
												</option>

												{this.state.groupsobject.map(
													(group) => (
														<option
															key={group.id}
															value={group.name}
														>
															{group.name}
														</option>
													)
												)}
											</select>
										</div>
									)}
								</div>
							</div>

							{isAddingNewTest && (
								<NewTestModal
									onSave={this.onSaveNewTest}
									userStage={userStage}
									userGroups={this.state.groupsobject}
									userLanguages={userLanguages}
									currentUser={this.props.currentUser}
								/>
							)}

							{isSendingPayload && (
								<NewPayloadModal
									onSave={this.onSaveNewTest}
									userStage={userStage}
									userGroups={this.state.groupsobject}
									userLanguages={userLanguages}
									closeWindow={() => {
										this.setState({
											isSendingPayload: false,
										});
									}}
									currentUser={this.props.currentUser}
									testResults={this.state.testResults}
									processTestResults={batchTestResult}
									infoPanelTestKey={
										this.state.infoPanelTestKey
									}
									onDistributeTest={this.loadMyTests}
									sendSePayload={(testUser) => {
										this.sendAndSaveSePayload(testUser);
									}}
								/>
							)}

							{isDumpingTests && (
								<DumpTests
									closeWindow={() => {
										this.setState({
											isDumpingTests: false,
										});
									}}
								/>
							)}

							{isViewingSeTests && (
								<ViewSeTests
									isForSeView={true}
									testsForDumping={seTestsForNorming}
									closeWindow={() => {
										this.setState({
											isViewingSeTests: false,
										});
									}}
								/>
							)}

							{openTrashCanModel && (
								<TrashCanModel
									closeWindow={() => {
										this.setState({
											openTrashCanModel: false,
										});
									}}
									getAndSetTestAdminTestResults={
										this.getAndSetTestAdminTestResults
									}
									adminId={currentUser.id}
									subsId={currentUser.subscription.id}
									deletedTests={deletedTests}
								/>
							)}

							{this.state.initiatesRedistribution && (
								<InformationPane
									paneMessage={this.state.paneMessage}
									variables={this.state.paneVariable}
									onApproval={(returnedProps) => {
										this.redistributeContinuedTest(
											returnedProps
										);
									}}
									onDisproval={() => {
										this.setState({
											initiatesRedistribution: false,
										});
									}}
									onCloseWindow={() => {
										this.setState({
											initiatesRedistribution: false,
										});
									}}
									category={'confirmation'}
								/>
							)}
							{this.state.initiateSoftDeletion && (
								<InformationPane
									paneMessage={this.state.paneMessage}
									variables={this.state.paneVariable}
									onApproval={(returnedProps) => {
										this.softDeleteTest(returnedProps);
									}}
									onDisproval={() => {
										this.setState({
											initiateSoftDeletion: false,
										});
									}}
									onCloseWindow={() => {
										this.setState({
											initiateSoftDeletion: false,
										});
									}}
									category={'confirmation'}
								/>
							)}

							{this.state.isAddingTestToGroup &&
								Boolean(
									this.state.existingKeysForGroup.length
								) && (
									<AddTestToGroup
										userGroups={this.state.groupsobject}
										groupPanel={
											this.state.isGroupPanelActive
										}
										selectedTests={
											this.state.existingKeysForGroup
										}
										closeWindow={() => {
											this.setState({
												isAddingTestToGroup: false,
											});
										}}
										onAddingTestToGroup={(
											groupId,
											selectedTests
										) => {
											this.addingTestToGroup(
												groupId,
												selectedTests
											);
										}}
										onRemovingFromGroups={(
											selectedTests
										) => {
											this.removingTestsFromGroups(
												selectedTests
											);
										}}
									/>
								)}
						</div>
						{this.renderUpgradeBox(
							isBasicUser,
							isTrialUser,
							trialIsActive
						)}
					</div>
				)}
				{this.state.batchedFilteredTestResults &&
					this.state.showMyTests && (
						<div className="lit-tests-view__contant">
							{this.state.batchedFilteredTestResults.length ===
								0 && (
								<p>{strings.no_tests_available_message}</p>
							)}
							{this.state.batchedFilteredTestResults.map(
								(monthBatch, index) =>
									this.renderMonth(monthBatch, index)
							)}
						</div>
					)}

				{this.state.showMyCandidates && !this.state.showMyTests && (
					<MyCandidates
						userStage={userStage}
						userGroups={this.state.groupsobject}
						groupedOption={this.state.groupedOption}
						userLanguages={userLanguages}
						hasInValidSubscription={hasInValidSubscription}
						currentUser={currentUser}
						testUsers={this.state.testUsers}
						isBasicUser={isBasicUser}
						isTrialUser={isTrialUser}
						trialIsActive={trialIsActive}
						isActiveUser={isActiveUser}
						testResult={this.state.batchedFilteredTestResults}
						onSaveBulk={this.onSaveNewBulkTest}
						batchedFilteredTestResults={
							this.state.batchedFilteredTestResults
						}
						adminClasses={this.state.adminClasses}
						classData={classData}
						onRenamingClass={(newTitleAndId) => {
							this.isRenamingClass(newTitleAndId);
						}}
						onAddingCandidates={(testCandidates) => {
							this.assignCandidatesToClass(testCandidates);
						}}
						revertIsCandidateDataFirstTimeRendered={
							this.revertIsCandidateDataFirstTimeRendered
						}
						isCandidateDataFirstTimeRendered={
							isCandidateDataFirstTimeRendered
						}
						onDeletingCandidates={(
							testCandidates,
							targetClass,
							subsId
						) => {
							this.deleteSelectedClassMembers(
								testCandidates,
								targetClass,
								subsId
							);
						}}
						onCreateClass={(
							usersIds,
							adminId,
							subsId,
							className,
							groupId
						) => {
							this.createNewAdminClass(
								usersIds,
								adminId,
								subsId,
								className,
								groupId
							);
						}}
					/>
				)}
				{this.state.showMyClasses &&
					!this.state.showMyTests &&
					!this.state.showMyCandidates && (
						<MyClasses
							hasInValidSubscription={hasInValidSubscription}
							userGroups={this.state.groupsobject}
							currentUser={currentUser}
							testUsers={this.state.testUsers}
							isBasicUser={isBasicUser}
							isTrialUser={isTrialUser}
							trialIsActive={trialIsActive}
							isActiveUser={isActiveUser}
							testResult={this.state.batchedFilteredTestResults}
							usersGroups={this.state.groupsobject}
							onSave={this.onSaveNewTest}
							userStage={userStage}
							userLanguages={userLanguages}
							batchedFilteredTestResults={
								this.state.batchedFilteredTestResults
							}
							onAddClassesToGroup={(classIds, groupIds) => {
								this.addSelectedClassesToGroup(
									classIds,
									groupIds
								);
							}}
							classes={classes}
							classData={classData}
						/>
					)}

				{/* Scrollers ending div */}
			</div>
		);
	}
}

MyTests.propTypes = {
	currentUser: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyTests);
