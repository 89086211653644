import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

export default function createNotification(content, subject, summery) {
	return fetch(
		`${ENDPOINT_URL}/createnotification`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				content,
				subject,
				summery,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			Promise.reject(apiErrorHandling(err));
			return err.ok;
		});
}
