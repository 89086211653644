import {ENDPOINT_URL} from 'constants/index';

import {updateObject} from 'utils/reducerUtils';
import getFetchOptions from 'utils/getFetchOptions';
import getUserStage from 'utils/getUserStage';
import {apiErrorHandling} from 'utils/apiUtils';

export default function getTestAdmins() {
	return fetch(
		`${ENDPOINT_URL}/adminusers?lang=${'swedish'}`,
		getFetchOptions({
			method: 'GET',
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.then((testAdmins) => {
			return testAdmins.map((testAdmin) =>
				updateObject(testAdmin, {userStage: getUserStage(testAdmin)})
			);
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
