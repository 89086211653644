export function formateTestName(testName) {
	switch (testName) {
		case 'ORDDIKTAT':
			return 'Stavning';
		case 'SKRIVEHASTIGHET':
			return 'Skrivflyt';
		case 'ORDKJEDETESTEN':
			return 'Ordkedjor';
		case 'LESEPRØVEN':
			return 'Läsförståelse';
		case 'TULLEORDTESTEN':
			return 'Nonsensord';
		case 'ORDFORSTÅELSE':
			return 'Ordförståelse';
		case 'BYTTELYDTESTEN':
			return 'Bytaljud';
		default:
			return (
				testName.charAt(0).toUpperCase() +
				testName.slice(1).toLowerCase()
			);
	}
}
