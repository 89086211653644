import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './styles.scss';

import {
	USER_STAGES_INFO,
	TRIAL,
	FULL,
	ADULT,
	YOUTH,
	CHILD,
	BASIC,
	ACTIVE,
	NORMING,
	PRODUKSJON,
	PROD_NORMING,
	CHILD_YOUTH,
	CHILD_ADULT,
	YOUTH_ADULT,
	CHILD_YOUTH_ADULT,
} from 'constants/index';

const UserStageIndicator = ({big, small, user, withText}) => {
	const {active, userStage, subscription} = user;
	const userStageInfo = USER_STAGES_INFO[userStage];

	const validStages = [
		FULL,
		ADULT,
		YOUTH,
		CHILD,
		BASIC,
		ACTIVE,
		NORMING,
		PRODUKSJON,
		PROD_NORMING,
		CHILD_YOUTH,
		CHILD_ADULT,
		YOUTH_ADULT,
		CHILD_YOUTH_ADULT,
	];
	let endMoment;
	let showValidUntil = false;
	let formattedDate;
	if (validStages.includes(userStage)) {
		endMoment = moment(subscription.subscriptionEndDate);
	} else if (userStage === TRIAL) {
		showValidUntil = true;
		endMoment = moment(subscription.trialValidUntil);
	}

	if (big) {
		let validUntilString = '';
		if (showValidUntil) {
			formattedDate = endMoment.format('DD.MM.YYYY');
			if (endMoment.isAfter(moment.now())) {
				validUntilString = `Gäller til ${formattedDate}`;
			} else {
				validUntilString = `Utløpte den ${formattedDate}`;
			}
		}

		return (
			<div className={'user-stage-indicator info__col lit-test-status'}>
				<h4 className="info__heading">{`Status`}</h4>
				<div className="info__status">
					<div
						className={classNames(
							'lit-test-status__indicator',
							userStageInfo.className,
							{
								active,
							}
						)}
					/>
					<p className="lit-test-status__label">
						{userStageInfo.text}
					</p>
				</div>
				<div className="info__extra">
					{endMoment && showValidUntil && (
						<div>{validUntilString}</div>
					)}
					<span>{userStageInfo.longText}</span>
				</div>
			</div>
		);
	}

	return (
		<div className={'user-stage-indicator'}>
			<span
				className={classNames(
					'lit-test-status__indicator',
					userStageInfo.className,
					{small, withText}
				)}
				title={withText ? '' : userStageInfo.text}
			/>
			{withText && (
				<span className="user-stage-info-text">
					{userStageInfo.text}
				</span>
			)}
		</div>
	);
};

UserStageIndicator.propTypes = {
	big: PropTypes.bool,
	small: PropTypes.bool,
	user: PropTypes.shape({}).isRequired,
	withText: PropTypes.bool,
};

export default UserStageIndicator;
