/* eslint-disable camelcase */

export default {
	mytest: `Mina tester`,
	myCandidates: `Mina Kandidater`,
	createnewtest: `Skapa nytt test`,
	started: `Påbörjat`,
	starttest: `Starta Test`,
	all: `Alla`,
	users: 'Användare',
	message_noTestsFound: 'Du har inga raderade tester',
	trashcan: 'Papperskorg',
	chooseagroup: `Välj en grupp`,
	distributed: `Distribuerat`,
	notdistributed: `Inte distribuerad`,
	notstarted: `Inte startat`,
	completed: `Genomfört`,
	completeplural: `Genomförda:`,
	seeresults: `Se resultat`,
	status: `Status`,
	testkey: `Testnyckel`,
	type: `Typ`,
	recipient: `Mottagare`,
	actions: `Åtgärder`,
	send: `Skicka`,
	resend: `Skicka igen`,
	save: `Spara`,
	sendcodeemail: `Skicka kod via email`,
	january: `januari`,
	february: `februari`,
	march: `mars`,
	april: `april`,
	may: `maj`,
	june: `juni`,
	juli: `juli`,
	august: `augusti`,
	september: `september`,
	october: `oktober`,
	november: `november`,
	december: `december`,
	norway: `Norge`,
	sweden: `Sverige`,
	germany: `Tyskland`,
	england: `Storbritannien`,
	nederland: `Nederländerna`,
	message_testcannotbedeleted: `Testet måste vara minst 3 månader gammalt för att raderas.`,
	warning_deletetest: `Testet kommer inte längre vara tillgängligt för bedömning. Vill du fortsätta?`,
	headerchildtest: `Testresultat för Dysmate – C (Barntest)`,
	dynamicheaderfortestresult_child: `Detaljerade resultat för Dysmate {0}`,
	dynamicheaderfortestreport_child: `Detaljerad rapport för Dysmate {0}`,
	dynamicheaderfortestcandidate_child: `Detaljerad information för Dysmatekandidat {0}`,
	dynamicheaderfortestresult_adult: `Detaljerade resultat för Dysmate {0}`,
	dynamicheaderfortestreport_adult: `Detaljerad rapport för Dysmate {0}`,
	dynamicheaderfortestcandidate_adult: `Detaljerad information för Dysmatekandidat {0}`,
	headeradulttest: `Resultat för Dysmate A (Screeningtest för vuxna)`,
	showbackground: `Visa kandidats bakgrundsinformation`,
	incompleteMessage: `Testet är inte genomfört. Delprov saknas eller har ej påbörjats.`,
	summary: `Sammanfattning`,
	printtestresults: `Skriv ut resultat`,
	zscorered: `Z-poäng mer än 1 standardavvikelse {0} genomsnittet`,
	zscoregreen: `Z-poäng mer än 1 standardavvikelse {0} genomsnittet`,
	zscoreblue: `Z-poäng ligger inom genomsnittsområdet`,
	overview: `Överblick`,
	assessment: `Bedömningar baserade på resultaten:`,
	textUnderEveryFollowupAssessment:
		'I Dysmate används regressionsbaserade normer för att beräkna testkandidatens resultat. Detta innebär att faktorn årskurs beaktas för att beräkna ett förväntat medelvärde för den grupp som testkandidaten tillhör. I deltesterna Arbetsminne och Spoonerismer ingår även kön som en faktor för att beräkna kandidatens resultat. Testkandidatens råpoäng jämförs med medelvärdet för att beräkna avståndet mellan dessa. Avståndet till medelvärdet beräknas genom att subtrahera medelvärdet från kandidatens råpoäng. Avståndet divideras sedan med standardavvikelsen för gruppen, vilket resulterar i en Z-poäng. Z-poängen visar testkandidatens resultat i förhållande till normgruppen, där medelvärdet är 0 och standardavvikelsen är 1.',
	textUnderEveryScreeningAssessment:
		'I Dysmate används regressionsbaserade normer för att beräkna testkandidatens resultat. Detta innebär att faktorn årskurs beaktas för att beräkna ett förväntat medelvärde för den grupp som testkandidaten tillhör. Testkandidatens råpoäng jämförs med medelvärdet för att beräkna avståndet. Avståndet till medelvärdet beräknas genom att subtrahera medelvärdet från kandidatens råpoäng. Avståndet divideras sedan med standardavvikelsen för gruppen, vilket resulterar i en Z-poäng. Z-poängen visar testkandidatens resultat i förhållande till normgruppen, där medelvärdet är 0 och standardavvikelsen är 1.',
	assessmentspeechok: `{0} har uppnått resultat inom normalområdet eller bättre för det experimentella enminutstestet och verkar ha goda läsförmågor.`,
	assessmentspeechnotok: `{0} har uppnått resultat under normalområdet för det experimentella enminutstestet och bör genomgå vidare utredning för dyslexi och/eller läsförståelsesvårigheter`,

	asessmenttextok_1: `{0} har uppnått resultat inom normalområdet eller bättre för {1} av delproven och verkar ha god läs- och skrivförmåga`,
	asessmenttextnotok_1: `{0} har uppnått resultat under normalområdet för {1} av de {2} delproven, och bör genomgå vidare utredning för dyslexi och/eller läsförståelsesvårigheter`,

	assesmenttextok_youthfollowup: `{0} resultat ger inga utslag på de typiskt kognitiva markörerna för dyslexi.`,
	assesmentok_recommendation_youthfollowup: `Men då {0} erhåller poäng under genomsnittet på läsförståelse- och/eller ordförståelsetestet, eller möjligen på något av de andra deltesterna i Dysmate screeningtest, rekommenderar vi en vidare utredning men tanke på möjlig läsförståelseproblematik och/eller underliggande språkliga svårigheter som vid språkstörning/DLD. `,

	workingmemorylowassesment: `Resultatet ger utslag på Arbetsminne, och {0} erhåller ett resultat som ligger {1} standardavvikelser under genomsnittet för sin årskurs eller ålder på detta test. Arbetsminne är i sig inte en kognitiv markör för dyslexi, men är förknippat med både dyslexi och läsförståelsesvårigheter. Nedsatt arbetsminne kan bland annat påverka hur mycket information man lyckas behålla från en läst text. Det rekommenderas därför att undersöka i vilken utsträckning arbetsminnet påverkar testkandidatens generella inlärningsförmåga och om det finns behov av skolbaserade insatser och anpassningar i detta avseende.`,

	rapidnominationlowassesment: `Resultatet ger utslag vid snabb automatiserad benämning, och {0} erhåller ett resultat som ligger {1} standardavvikelser under genomsnittet för sin årskurs eller ålder vid detta test. Snabb automatiserad benämning mäter hur snabbt och exakt man kan hämta fram ord från språkminnet. Svaga färdigheter inom detta område är en kognitiv markör för dyslexi.`,

	oneminutelowassesment: `Resultatet ger utslag vid 1-minuts ordavkodning och {0} erhåller ett resultat som ligger {1} standardavvikelser under genomsnittet för sin årskurs eller ålder vid detta test. 1-minuts ordavkodning ger ett rent mått på antalet korrekt lästa ord per minut och ger en bra bild av avkodningsförmågan. Svag avkodningsförmåga är en markör för dyslexi.`,

	oneminutelowassesment2: `OBS! Vi rekommenderar att resultatet vid 1-minuts ordavkodning tas i beaktande tillsammans med resultaten från Stavnings- och Ordkedjetestet i Dysmate screeningtest.`,

	spoonerismlowassesment: `Resultatet ger utslag vid Spoonerismer, och {0} erhåller ett resultat som ligger {1} standardavvikelser under genomsnittet för sin årskurs eller ålder på detta test. Spoonerism-testet mäter fonemmedvetenhet och fonologisk bearbetningshastighet. Svaga färdigheter inom dessa områden är en kognitiv markör för dyslexi.`,

	rawscore: `Rå poäng`,
	zscore: `Z-poäng`,
	percentilescore: `Percentilpoäng`,
	tscore: `T-poäng`,
	scaledscore: `Skalpoäng`,
	normalrange: `Normalområde`,
	assessmentsubtestnormal: `Detta resultat ligger inom normalområdet`,
	assessmentsubtestbelow: `Detta resultat ligger {0} standardavvikelser {1} genomsnittet`,
	under: `under`,
	assessmentsubtestabove: `Detta resultat ligger {0} standardavvikelser {1} genomsnittet`,
	over: `över`,
	workingmemorytest: `Arbetsminne`,
	spoonerismtest: `Spoonerism`,
	oneminutetest: `Enminutstest`,
	letterknowledgetest: `Bokstavskunskap`,
	phonemisolationtest: `Fonemisolation`,
	worddictationtest: `Diktion`,
	phonemeomissiontest: `Fonemutelämnande`,
	wordchaintest: `Ordkedjetest`,
	nonwordtest: `Låtsasordstest`,
	typingspeedtest: `Skrivhastighetstest`,
	readingtest: `Läsförståelse`,
	wordcomprehensiontest: `Ordförståelse`,
	loggin: `Logga in`,
	loggout: `Logga ut`,
	trialperiod: `Provperiod`,
	trialuser: `Provanvändare`,
	basicsupcription: `Grundläggande prenumeration`,
	fullsupscription: `Full prenumeration`,
	childsubscription: `Dysmate-C - prenumeration`,
	adultsubscription: `Dysmate-A - prenumeration`,
	youthsubscription: `Dysmate-Y - prenumeration`,
	name: `Namn:`,
	firstname: `Förnamn`,
	lastname: `Efternamn`,
	dateofbirth: `Födelsedatum:`,
	birthDate: `Födelsedatum`,
	paneMessageContinueTest: `Detta kommer skicka ut en ny länk till testet så att kandidaterna kan fortsätta ett ofullständig test. Fortsätt?`,
	telephone: `Telefon`,
	email: `E-post`,
	edit: `Redigera`,
	edituser: `Redigera användare`,
	seesubscription: `Se prenumeration`,
	adulttests: `Dysmate-A (Vuxentest)`,
	youthtests: `Dysmate-Y (Ungdomstest)`,
	confirmationofcost: `Prisbekräftelse`,
	sex: `Kön`,
	male: `Man`,
	female: `Kvinna`,
	education: `Utbildning`,
	cancel: `Avbryt`,
	confirm: `Bekräfta`,
	age: `Ålder`,
	age_specified: `Ålder (år)`,
	testdate: `Testdatum`,
	childtest: `Dysmate-C (Barntest)`,
	experimentalchildtest: `Experimentellt barntest`,
	youthtest: `Screeningtest (ungdom, åk 7-9)`,
	adulttest: `Dysmate-A (Screening)`,
	adultscreening: `Dysmate A – (Screeningtest för vuxna)`,
	printedBy: 'Tryckt av:  ',
	youthscreening: `Dysmate-Y – (Screeningtest för ungdomar)`,
	childtests: `Dysmate-C (barntest)`,
	nousedtests: `Ingen använde {0} test`,
	used: `Använda:`,
	boy: `Pojke`,
	girl: `Flicka`,
	logg: `Händelselogg`,
	of: `av {0}`,
	wordsperminute: `ord per minut`,
	deleteoldtests: `Radera gamla test`,
	addtesttogroup: `Lägg till test till en grupp`,
	message_incompletData: `Ogiltig eller ofullständig uppgift`,
	newclasscreated: `Ny klass skapad`,
	testcandidateremoved: `Testkandidaten har raderats från klasslistan`,
	testcandidateadded: `Testkandidaten har lagts till I klasslistan`,
	classexistchoosename: `Denna klass finns redan, vänligen välj ett annat namn.`,
	class: `.klass`,
	subscription: `Prenumeration`,
	studentID: `Student-ID`,
	supersubscription: `Har prenumeration`,
	mysubscription: `Min prenumeration`,
	seetests: `Se test`,
	currencysymbol: `SEK`,
	hideWithArrow: `Dölj ▲`,
	yourtrialperiodhasexpired: `Din provperiod har tagit slut`,
	upgradeyoursubscription: `Uppgradera din prenumeration`,
	downloadandsendcontract: `För att utfärda fler test måste du ha en prenumeration. Gå till “Min sida” på webbplatsen för att teckna abonnemang, eller ladda ner kontraktet och skicka det till `,
	priceandconditioninfo: `. Prisinformation och köpevillkor finns på `,
	downloadcontract: `Ladda ner kontrakt`,
	onlytwotestspertrialperiod: `Endast 2 test är tillgängliga under provperioden`,
	upgradetofullsubscription: `uppgradera till full prenumeration för att utfärda nya test.`,
	fetch: `Återställ`,
	onlyTrialAccess: `Har endast tillgång till totalt 12 tester`,
	language: `Språk`,
	child: `Barn`,
	youth: `Ungdom`,
	adult: `Vuxen`,
	youthfollowup: `Uppföljningstest (ungdom, åk 7-9)`,
	youthfollowuptest: `Uppföljningstest (ungdom, åk 7-9)`,
	adultfollowup: `Dysmate-A II (Uppföljning vuxen)`,
	adultfollowuptest: `Dysmate-A II (Uppföljningstest vuxen)`,
	childfollowuptest: `Dysmate-C II (Uppföljning barn)`,
	childnorming: `Normering barn`,
	youthnorming: `Normering ungdom`,
	adultnorming: `Normering vuxen`,
	deletedon: `Raderade`,
	basic: `Grundläggande`,
	deletedtests: `Raderade test`,
	viewsubscription: `Se prenumeration ▼`,
	viewdeleted: `Raderade test ▼`,
	referalinformation: `personuppgifter`,
	referalgroups: `gruppmedlemskap`,
	mygroups: `Mina grupper`,
	myinformation: `Mina personuppgifter`,
	message_costsforeachtestthatis_started: `Observera att det, beroende på din prenumeration, kan finnas kostnader för att utfärda nya tester. Tester som inte används kommer inte att debiteras.`,
	test_type: `Testtyp`,
	screeningtest_adult: `Dysmate-A (screeningtest vuxen)`,
	numberoftests: `Antal test (max 30)`,
	create_tests: `Skapa nya test`,
	login: `Logga in`,
	loginwithbankid: `Logga in med följande metoder`,
	message_thisisforcertifieduser: `Detta är för certifierade testadministratörer med prenumeration.`,
	bankid: `BankID`,
	bankidonmobile: `Mobilt BankID`,
	edittestadministrator: `Ändra testadministratör`,
	personalinformation: `Personuppgifter`,
	shcool_or_company: `Skola/företag`,
	addanewtestadministrator: `Lägg till ny testadministratör`,
	addtestadministrator: `Lägg till testadministratör`,
	saveuserinformation: `Spara information om testkandidaten`,
	delete: `Radera`,
	deleteuser: `Radera användare`,
	currentstatus: `Nuvarande status:`,
	savesubscription: `Spara prenumeration`,
	organization: `Organisation`,
	newloginmethod: `Ny inloggningsmetod`,
	errorhasoccured: `Ett fel har inträffat`,
	message_singleuser_perks_trial: `Med en användare kan du få en gratis provperiod på 30 dagar och 2 gratis test av varje typ`,
	message_change_loginmethod: `Du är på väg att ändra din inloggningsmetod. Du kommer fortsätta behöva logga in med denna metod.`,
	info_for_professional_with_either_completed_screening_test: `Detta är för yrkesverksamma som slutfört eller är anmälda till en certifieringskurs och som vill prova några tester innan de tecknar abonnemang.`,
	info_prerequisites_to_obtain_license: `För att få tillgång till provperioden måste du anmäla dig till eller ha slutfört dysmate-certifieringskursen. Alla användare kommer att godkännas manuellt av Dysmate, varefter de kommer att få instruktioner i ett e-postmeddelande för att slutföra sin registrering för att få tillgång.`,
	removefiltering: `Ta bort filter`,
	printreport: `Skriv ut testrapport`,
	hide: `Dölj`,
	show: `Visa`,
	userinformation: `Kandidatinformation`,
	localizedwriteuptextforNormalresults:
		'{0} uppnår resultat som ligger  {1} {2} {3} genomsnittet vid {4} av deltesterna. Detta indikerar att {5} har goda läs- och skrivfärdigheter.',
	localizedwriteuptextforOneOtherSubTest:
		'{0} har uppnått resultat som ligger {1} {2} {3}. {4} {5}',
	localizedwriteuptextforTwoOtherSubTest:
		'{0} har uppnått resultat som ligger {1} {2} {3}. {4} Eftersom {5} uppnår svaga resultat på {6}, {7} og {8} rekommenderas att kandidaten genomför Dysmate uppföljningstest.',
	localizedwriteuptextforThreeORMoreOtherSubTest:
		'{0} har uppnått resultat som ligger {1} {2} {3}. {4} Eftersom {5} uppnår svaga resultat på {6}, {7}, {8} {9} {10} {11} rekommenderas att kandidaten genomför Dysmate uppföljningstest.',
	localizedwriteuptextforIncompletedTest:
		'{0} har inte genomfört hela screeningtestet och det saknas därmed underlag för att ge några rekommendationer om ytterligare uppföljning. ',

	localizedwriteuptextforLowScoreOfOrdiktatWithOneOtherSubTest:
		'Stavningssvårigheter kan vara en indikation på dyslexi. {0} resultat på stavningstestet indikerar att ett Dysmate uppföljningstest bör genomföras.',
	localizedwriteuptextforLowScoreOfBetyledeWithOneOtherSubTest: '',
	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelse:
		'{0} har uppnått resultat som ligger {1} {2} {3}. {4} Eftersom {5} uppnår svaga resultat på ordförståelse- och läsförståelsetestet rekommenderas att testkandidaten genomför Dysmate uppföljningstest. ',
	testreultsforliteratescreeningtest: `Testresultat för Dysmate screeningtest`,
	info_belowpresentsinformation: `Nedenfor presenteres testresultaten för {0}, ålder {1} år och {2} månad presenteras nedan.`,
	newinfo_belowpresentsinformation: `Nedenfor presenteres testresultaten för {0} Född: {1} {2}`,
	newinfo2_belowpresentsinformation: `Nedenfor presenteres testrapport for {0} Född: {1} {2}`,
	newinfo3_belowpresentsinformation: `Kandidatinformation för {0}, född {1}, (Ålder: {2} år eller {3} månader) presenteras nedan.`,
	speech1_belowpresentsinformation: `Testresultat för {0}, (Ålder: {1}) presenteras nedan.`,
	speech2_belowpresentsinformation: `Testrapport för {0}, (Ålder: {1}) presenteras nedan.`,
	speech3_belowpresentsinformation: `Kandidatinformation för {0}, (Ålder: {1} år) presenteras nedan.`,
	info_testwascompletedon: `Testet slutfördes den {0}`,
	info_testwasNotcompletedon: ` Detta test är ej slutfört.`,
	notetsfound: `Inga slutförda test hittades`,
	background_info_testcandidate: `bakgrundsinformation för testkandidaten`,
	candidate_answers: `Svar från kandidaten`,
	selectLanguage: `Välj språk`,
	norwegian: `Norska`,
	german: `Tyska`,
	dutch: `Nederländska`,
	english: `Engelska`,
	swedish: `Svenska`,
	zscore: `Z-poäng`,
	usersupport: `Användarstöd`,
	groups: `Grupper`,
	groupname: `Gruppnamn`,
	date: `Datum`,
	insert: `Infoga`,
	selected_users: `Välj användare`,
	alreadyexistsingroup: `finns redan I gruppen!`,

	orddiktatheading: `Varje ord presenteras först i en mening för att säkerställa att den korrekta innebörden av ordet som ska stavas förstås. Därefter ombeds kandidaten att skriva målordet som då upprepas. Det finns ingen tidsgräns på detta deltest och både mening och målord kan upprepas en gång.`,
	orddiktatchildexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 13 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2} .`,
	orddiktatvariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger`,
	orddiktatadultexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 45 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2} .`,
	orddiktatyouthexplanation: `En poäng ges för varje korrekt stavat ord (max 30 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,

	fonemisolasjonheading: `Detta test identifierar fonemmedvetenhet hos de yngsta testkandidaterna. I detta test visas fyra bilder för testkandidaten och hen ombeds peka på den bild som antingen börjar eller slutar med ett visst ljud.`,
	fonemisolasjonexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 16 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	fonemisolasjonvariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från detta stegs medelvärde. Detta resultat placeras`,
	fonemutelatelseheading: `Detta test identifierar fonemmedvetenhet hos elever I grundskolan. I detta test presenteras ord verbalt för testkandidaten och denne ombeds att säga det ord som återstår när ett visst ljud tas bort (t.ex. / b / i bröd => röd)`,
	fonemutelatelseexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 16 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	fonemutelatelsevariabletext: `{0} uppnådde en poäng på {1} på detta test. Denna poäng placeras {2} standardavvikelser från detta stegs medelvärde. Detta resultat placeras`,

	spoonerismheading: `Detta test mäter fonemmedvetenhet och fonologisk bearbetningshastighet. Testkandidaten får 20 ordpar presenterade för sig muntligt och ombeds sedan att byta plats på de första ljuden i de två orden så snabbt och korrekt som möjligt.`,
	spoonerismexplanation: `En poäng ges för varje korrekt ord som kandidaten lyckas säga med det nya första ljudet (max 40 poäng). Testet är också tidsbestämt. Poängen beräknas utifrån antalet korrekta ord som har ändrat sitt första ljud, dividerat med den tid det tar att slutföra uppgifterna. Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.  `,
	spoonerismvariabletext: `{0} uppnår en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	ettminuttstestheading: `Detta är ett högläsningstest som bedömer ordavkodningsförmåga. Kandidaten får en lista med ord och ombeds att läsa så snabbt och korrekt som möjligt inom en tidsram på 60 sekunder. Resultatet ger ett mått på antalet korrekt lästa ord per minut.`,
	ettminuttstestexplanation: `En poäng ges för varje korrekt läst ord. Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,
	ettminuttstestvariabletext: `{0} uppnår en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	hurtigbenevningheading: `Detta test bedömer hur snabbt kandidaten kan namnge bekanta föremål och hämta fram namn på föremål och färger från det språkliga långtidsminnet.`,
	hurtigbenevningexplanation: `En poäng ges för varje korrekt kombination av färg och föremål som kandidaten namnger. Testet är också tidsbestämt. Poängen beräknas utifrån antalet korrekta färg- och föremålskombinationer dividerat med den totala tiden det tar att nämna alla 50 färg- och föremålskombinationer. Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}. `,
	hurtigbenevningvariabletext: `{0} uppnår en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	arbeidsminnenummberheading: `Detta test mäter kandidatens förmåga att lagra och bearbeta information samtidigt som man löser en uppgift. Testkandidaten presenteras först för en serie med siffror och sedan en serie med ord. Dessa ska sedan upprepas i omvänd ordning från hur de presenterades. Både sifferserien och ordserien ökar i längd.`,
	arbeidsminneexplanation: `För varje rätt svar ges en poäng (max 12 poäng för sifferserien och max 12 poäng för ordserien, totalt max 24 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}. `,
	arbeidsminnevariabletext: `{0} uppnår en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,
	arbeidsminneheading: `Detta test identifierar testkandidatens förmåga att lagra och bearbeta information. I detta test presenteras för testkandidaten en serie siffror, sedan en serie ord, vilka ska upprepas i motsatt ordning. Både sifferraderna och ordraderna ökar I längd om testkandidaten klarar den föregående raden.`,

	ordforståelseheading: `Testet mäter ordförståelse genom att testkandidaten ombeds hitta ett ord som har samma betydelse som ett givet målord. I varje uppgift får testkandidaten välja mellan fyra alternativ. Detta test ger ett mått på ordförrådets bredd och djup.`,
	ordforståelseexplanation: `Ett poäng tillskrivs för varje korrekt svar (maximalt 15 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	ordforståelsevariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,
	ordforståelseyouthexplanation: `För varje rätt svar erhålls en poäng (max 20 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,

	leseprøvenheading: `Detta test är ett tyst läsprov som mäter läsförståelse genom att testkandidaten måste svara på frågor direkt från texten, sammanställa information från olika avsnitt och dra slutsatser. Testkandidaten har 10 minuter på sig att läsa texten och svara på så många flervalsfrågor som möjligt. N.B. Här är det viktigt att registrera om testkandidaten har svarat fel eller lämnat flervalsfrågorna obesvarade (på grund av lågläshastighet).`,
	leseprøvenyouthheading: `Detta test är ett tyst lästest som mäter läsförståelse genom att testkandidaten får svara på frågor, sammanfatta information från olika avsnitt och dra slutsatser utifrån en text. Testkandidaten får 12 minuter på sig att läsa texten och svara på så många flervalsfrågor som möjligt. OBS! Här är det viktigt att registrera om testkandidaten har svarat fel eller inte besvarat flervalsfrågorna (på grund av sin läshastighet).`,
	leseprøvenexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 14 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}`,
	leseprøvenyouthexplanation: `För varje rätt svar erhålls en poäng (max 9 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,
	leseprøvenvariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	skrivehastighetheading: `Detta test mäter skrivflyt, alltså hur effektivt en person kan skriva. Detta är en förmåga som är viktig, exempelvis när man antecknar eller skriver ett prov. Kandidaten ser hela tiden en mening framför sig och ska skriva av den så många gånger och så korrekt som möjligt inom 2 minuter.`,
	skrivehastighetexplanation: `Poängen baseras på antalet korrekt skrivna ord i genomsnitt per minut. Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,
	skrivehastighetvariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	tulleordtestenheading: `Detta test är ett tyst lästest som mäter fonologisk medvetenhet och fonologisk bearbetningshastighet. Detta anses vara en språkligt-kognitiv markör för dyslexi. Testkandidaten måste hitta det nonsensord som låter som ett riktigt ord. Vid varje uppgift måste testkandidaten välja mellan fem alternativ, varav endast ett är korrekt. Testkandidaten har två minuter på sig att lösa så många uppgifter som möjligt.`,
	tulleordtestenexplanation: `För varje rätt svar erhålls en poäng (max 25 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,
	tulleordtestenvariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,

	ordkjedetestenheading: `Detta är ett tyst lästest som mäter snabb ordigenkänning och ortografisk kunskap. Här ombeds testkandidaten att skapa ett mellanrum mellan ord som är ihopskriva i en kedja. Varje kedja består av fyra slumpmässiga ord. Testkandidaten får 4 minuter på sig att lösa så många ordkedjor som möjligt.`,
	ordkjedetestenexplanation: `Ett poäng tilldelas för varje korrekt svar (maximalt 40 poäng). Genomsnittspoängen för {0} är {1} och standardavvikelsen är {2}.`,
	ordkjedetestenvariabletext: `{0} uppnådde en poäng om {1} på detta deltest. Detta resultat ligger {2} standardavvikelser från medelvärdet för årskursen. {3} uppnår därmed ett resultat som ligger `,
	ordkjedetestenyouthexplanation: `En poäng ges för varje korrekt svar (max 36 poäng). Förväntat testresultat, baserat på testkandidatens årskurs, är {0} och standardavvikelsen är {1}.`,
	grade: `Årskurs`,
	infor_scr_ageAtCommencement:
		' Ålder vid testdatum var {0} år och {1} månader.',
	printInformation: 'Skriv ut kandidatinformation',
	chooseagroup: `Välj en grupp`,
	averageForGrade: 'medelvärdet för årskursen.',
	includeLongIntro: `Inkludera självbedömning / anamnese`,
	anamneseexplaination: `Om testet enbart används för screening är det inte nödvändigt att inkludera självbedömning / anamnese`,
	chooselanguage: `Välj språk`,
	seegrouptests: `Se tester i grupper`,
	addtogroup: `Lägg till i gruppen`,
	nogroupsfound: `Inga grupper funna`,
	fromgroups: `Från grupp`,
	allmytests: `Alla mina tester`,
	mytests: `Mina tester`,
	groupindicator: `-----Grupper-----`,
	notanswered: `ej besvarad`,
	testnotanswered: `{0} frågor ej besvarade`,
	showunanswered: `Visa obesvarade`,
	hideunanswered: `Dölj obesvarade`,
	rightanswer: `Korrekt besvarade: {0}`,
	action: `Åtgärd`,
	deleteall: `Radera alla`,
	chooseclass: `Välj klass`,
	confirmationdeleteclass: `Är du säker på att du vill radera detta {0}`,
	theclass: `Klass`,
	multipleclasses: `klasser`,
	myclasses: `Mina klasser`,
	deleteclass: `Radera klass`,
	message_noclasses: `Du har inte skapat några klasser`,
	showtests: `Visa tester ▼`,
	allmycandidates: `Alla mina kandidater`,
	notestsforcandidate: `Denna kandidat har inte slutfört några tester`,
	gender: `Kön`,
	nocandidates: `Du har inga kandidater`,
	choosecandidate: `Välj en kandidat`,
	classindicator: `-----Klasser-----`,
	sendtest: `Skicka ut test`,
	createclass: `Skapa Klass`,
	removefromclass: `Ta bort från klassen`,
	providevalidname: `Ange ett giltigt namn`,
	searchhere: `Sök här...`,
	searchByTestKey: 'Sök Test nyckel',
	createNotification: 'Opprett et varsling',
	viewNotification: 'Se varslinger',
	postNotification: 'Post Notification',
	areyousure: 'Är du säker?',
	editNotification: 'Rediger Varsling',
	notificationcreated: 'Varning skapad',
	allnotifications: 'Alla aviseringar',
	nonotificationsfound: 'Inga varningar hittades',
	createnotificationwarning:
		'Du kan inte ändra meddelandet när det väl har lagts upp',
	newclass: `Ny Klass`,
	writeclassname: `Ge klassen ett namn…`,
	deleting: `Raderar`,
	mypage: `Min sida`,
	notavailable: `ej tillgänglig`,
	theagegroup: `åldersgrupp`,
	hiddenname: `<Dolt namn>`,
	wordreadcorrectly: `Korrekt lästa ord`,
	correct: `Korrekt`,
	wrong: `Fel`,
	followuptests: `Uppföljningstester`,
	timeused: `Tid använd`,
	thisagegroup: `åldersgrupp`,
	adminpersonalinformation: `Personuppgifter`,
	readingTest_questioncount: 14,
	password: `Lösenord`,
	confirmpassword: `Bekräfta lösenord`,

	assessmentscreening: `Gesamtbewertung auf der Grundlage der Ergebnisse 
	aller Subtests aus dem Screening:`,
	assessmentfollowup: `Gesamtbewertungen auf der Grundlage der Ergebnisse 
	aller Subtests aus dem Follow-up`,
	summarypretext: `Denna figur visar {0} individuella resultat i screeningdeltesten jämfört med normurvalet. De så kallade Z-värdena (standardiserade värden; medelvärde 0, standardavvikelse 1) visas
	`,
	inforeferall: `För elevens specifika profil hittar du resultaten från screeningens individuella delprov nedan. För en övergripande illustration av kategoriseringen av testvärden kan du också ta en titt på grafiken.
	`,
};
