import {ENDPOINT_URL} from 'constants/index';

const headers = new Headers();
headers.append('Content-Type', 'application/json');

const authErrorMessage = {
	message: `Kunne ikke logge inn!`,
};

function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export const authCallbackCreator = (
	key,
	regToken,
	method,
	changeIntent,
	resolve,
	reject
) => {
	const authCallback = (event) => {
		let data = event.data ? event.data : null;
		if (event.data && IsValidJSONString(event.data)) {
			data = JSON.parse(event.data);
		} else {
			return;
		}
		window.removeEventListener('message', authCallback);

		if (data.authError) {
			reject({message: 'There was an error logging you in'});
			return;
		}

		if (data && data.action === 'auth' && data.result === 'OK') {
			localStorage.setItem('authtoken', data.authToken);
			if (regToken && changeIntent === 'verify') {
				window.location.href = `/new-method/?&id=${regToken}&mtd=${method}`;
				resolve();
				return;
			} else {
				window.location.href = `/my-tests`;
				resolve();
				return;
			}
		}
		reject(authErrorMessage);
	};

	return authCallback;
};

export const initAuth = (
	provider,
	key,
	regToken,
	chosenMethod,
	changeIntent
) => {
	const payload = {
		provider,
		origin: 'backoffice',
	};
	if (key) {
		payload.key = key;
	}

	if (regToken && changeIntent) {
		payload.intent = changeIntent;
		payload.userRegId = regToken;
	}

	const body = JSON.stringify(payload);
	return fetch(`${ENDPOINT_URL}/initAuth`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body,
	}).then((response) => {
		if (response.ok) {
			return response.json();
		}
		return Promise.reject(response);
	});
};
