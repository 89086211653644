import test0No from './answers/no/answers-test0.json';
import test3No from './answers/no/answers-test3.json';
import test4No from './answers/no/answers-test4.json';
import test5No from './answers/no/answers-test5.json';
import test6No from './answers/no/answers-test6.json';
import testBH6No from './answers/no/answers-test6-BH.json';
import test7No from './answers/no/answers-test7.json';
import testBH7No from './answers/no/answers-test7-BH.json';
import test8No from './answers/no/answers-test8.json';
import test9No from './answers/no/answers-test9.json';

import test0Nn from './answers/nn/answers-test0.json';
import test3Nn from './answers/nn/answers-test3.json';
import test4Nn from './answers/nn/answers-test4.json';
import test5Nn from './answers/nn/answers-test5.json';
import test6Nn from './answers/nn/answers-test6.json';
import testBH6Nn from './answers/nn/answers-test6-BH.json';
import test7Nn from './answers/nn/answers-test7.json';
import testBH7Nn from './answers/nn/answers-test7-BH.json';
import test8Nn from './answers/nn/answers-test8.json';
import test9Nn from './answers/nn/answers-test9.json';

import test0Se from './answers/se/answers-test0.json';
import test3Se from './answers/se/answers-test3.json';
import test4Se from './answers/se/answers-test4.json';
import test5Se from './answers/se/answers-test5.json';
import test6Se from './answers/se/answers-test6.json';
import testBH6Se from './answers/se/answers-test6-BH.json';
import test7Se from './answers/se/answers-test7.json';
import testBH7Se from './answers/se/answers-test7-BH.json';
import test8Se from './answers/se/answers-test8.json';
import test9Se from './answers/se/answers-test9.json';

export const no = {
	test0: test0No,
	test3: test3No,
	test4: test4No,
	test5: test5No,
	test6: test6No,
	testBH6: testBH6No,
	test7: test7No,
	testBH7: testBH7No,
	test8: test8No,
	test9: test9No,
};

export const nn = {
	test0: test0Nn,
	test3: test3Nn,
	test4: test4Nn,
	test5: test5Nn,
	test6: test6Nn,
	testBH6: testBH6Nn,
	test7: test7Nn,
	testBH7: testBH7Nn,
	test8: test8Nn,
	test9: test9Nn,
};

export const se = {
	test0: test0Se,
	test3: test3Se,
	test4: test4Se,
	test5: test5Se,
	test6: test6Se,
	testBH6: testBH6Se,
	test7: test7Se,
	testBH7: testBH7Se,
	test8: test8Se,
	test9: test9Se,
};
