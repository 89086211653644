import React, {Component} from 'react';

import {
	TEST_STEPS,
	TEST_STEPS_BY_ID,
	YOUTH_TEST_STEPS_BY_ID,
	CHILD_TEST_STEPS_BY_ID,
	CHILD_SCRNG_TEST_STEPS_BY_ID,
	Z_SCORE_OFFSET,
	Z_SCORE_MAX,
} from 'constants/index';
import strings from 'utils/strings';
import roundWithDecimals from 'utils/roundWithDecimals';

class Subtest extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// eslint-disable-next-line complexity
	render() {
		const {subtest, type, testResult} = this.props;
		const {testUser} = testResult;
		const foreign =
			type.includes('screening') &&
			testUser.homeLanguage.grewUpInBilingualHome === true
				? 1
				: !type.includes('screening') &&
				  testUser.languagesInHome !== 'one'
				? 1
				: 0;
		const gender = testUser.gender === 'f' ? 0 : 1;
		const testUserPronoun =
			testUser.gender === 'm'
				? 'Han'
				: testUser.gender === 'f'
				? 'Hun'
				: 'Hen';
		const classInfo =
			type === 'child'
				? testUser.class || testUser.ageGroup
				: type === 'child-screening' || type === 'youth-screening'
				? testUser.education.youthGrade
				: testUser.education.garde ||
				  testUser.class ||
				  strings.theagegroup;
		const ageGroup =
			classInfo === 'yrkesfag' ||
			classInfo === 'bachelor' ||
			classInfo === 'master' ||
			classInfo === 'phd' ||
			classInfo === 'other' ||
			classInfo === 'studiespesialisering'
				? 'adult'
				: classInfo;
		const isTestOwner = testResult.isTestOwner;

		let completeName = strings.hiddenname;
		let onlyFirstName = strings.hiddenname;
		if (testUser.firstName) {
			completeName = `${testUser.firstName} ${testUser.lastName}`;
			onlyFirstName = testUser.firstName;
		}
		const {
			totalCorrect,
			id,
			zScore,
			percentile,
			tScore,
			scaledScore,
			vars,
		} = subtest;

		const zScoreImageLabelWidth = 20.79;
		const zScoreImageRightPaddingWidth = 2.25;
		const zScoreChartWidth =
			100 - zScoreImageRightPaddingWidth - zScoreImageLabelWidth;

		let subtestInfo = null;
		switch (type) {
			case 'child':
				subtestInfo = CHILD_TEST_STEPS_BY_ID[id];
				break;
			case 'adult-screening':
				subtestInfo = TEST_STEPS_BY_ID[id];
				break;
			case 'youth-screening':
				subtestInfo = YOUTH_TEST_STEPS_BY_ID[id];
				break;
			case 'child-screening':
				subtestInfo = CHILD_SCRNG_TEST_STEPS_BY_ID[id];
				break;
		}
		const subtestQuestionCount = subtestInfo.questionCount;
		const subtestLabel = subtestInfo.label;
		const subTestReportHeading = subtestInfo.reportHeading;

		let totalCorrectLabel;
		let formattedVariableText;
		let subtestExplanation = '';
		let meanScore;
		let standardDeviation;

		if (type === 'child') {
			let myVars;
			if (vars) {
				myVars = JSON.parse(vars);
			}

			switch (id) {
				case 0: {
					totalCorrectLabel = `${totalCorrect}  rette`;
					meanScore = subtestInfo['getPredictedScore'](
						foreign,
						testUser.ageGroup
					);
					standardDeviation = subtestInfo.norms[ageGroup]
						? subtestInfo.norms[ageGroup].standardDeviation
						: strings.notavailable;

					formattedVariableText = strings.formatString(
						subtestInfo.reportVariableText,
						`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
						`${totalCorrect}`,
						`${roundWithDecimals(zScore, 2)}`,
						`${testUserPronoun}`
					);
					subtestExplanation = strings.formatString(
						subtestInfo.reportExplaination,
						<b>{meanScore}</b>,
						<b>{standardDeviation}</b>
					);
					break;
				}
				case 3: {
					let totalErrors = 0;
					let duration = 0;
					meanScore = subtestInfo['getPredictedScore'](
						foreign,
						testUser.ageGroup
					);
					standardDeviation = subtestInfo.norms[ageGroup]
						? subtestInfo.norms[ageGroup].standardDeviation
						: strings.notavailable;
					subtestExplanation = strings.formatString(
						ageGroup == 1 || ageGroup == 0
							? subtestInfo.reportBHexplaination
							: subtestInfo.reportExplaination,
						<b>{meanScore}</b>,
						<b>{standardDeviation}</b>
					);
					formattedVariableText = strings.formatString(
						subtestInfo.reportVariableText,
						`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
						`${roundWithDecimals(totalCorrect, 2)}`,
						`${roundWithDecimals(zScore, 2)}`,
						`${testUserPronoun}`
					);
					if (myVars && myVars.duration) {
						duration = myVars.duration;
						totalErrors = myVars.totalErrors;
					}
					totalCorrectLabel = `\n${strings.timeused}: ${Math.round(
						duration / 1000
					)} sekunder\nAntall feil: ${totalErrors}`;
					break;
				}
				case 4: {
					totalCorrectLabel = `${totalCorrect}  ${strings.wordreadcorrectly}`;
					meanScore =
						totalCorrectLabel = `${totalCorrect}  ${strings.wordreadcorrectly}`;
					meanScore = subtestInfo['getPredictedScore'](
						foreign,
						testUser.ageGroup
					);
					standardDeviation = subtestInfo.norms[ageGroup]
						? subtestInfo.norms[ageGroup].standardDeviation
						: strings.notavailable;
					subtestExplanation = strings.formatString(
						subtestInfo.reportExplaination,
						<b>{meanScore}</b>,
						<b>{standardDeviation}</b>
					);
					formattedVariableText = strings.formatString(
						subtestInfo.reportVariableText,
						`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
						`${totalCorrect}`,
						`${roundWithDecimals(zScore, 2)}`,
						`${testUserPronoun}`
					);

					break;
				}
				case 6: {
					totalCorrectLabel = `${totalCorrect}  ${subtestQuestionCount}`;
					meanScore = subtestInfo['getPredictedScore'](
						foreign,
						testUser.ageGroup
					);
					standardDeviation = subtestInfo.norms[ageGroup]
						? subtestInfo.norms[ageGroup].standardDeviation
						: strings.notavailable;
					subtestExplanation = strings.formatString(
						subtestInfo.reportExplaination,
						<b>{meanScore}</b>,
						<b>{standardDeviation}</b>
					);
					formattedVariableText =
						ageGroup == 0
							? strings.formatString(
									subtestInfo.reportVariableTextBH,
									`${
										isTestOwner
											? onlyFirstName
											: strings.hiddenname
									}`,
									`${totalCorrect}`,
									`${
										isTestOwner
											? onlyFirstName
											: strings.hiddenname
									}`,
									`${testUserPronoun}`
							  )
							: strings.formatString(
									subtestInfo.reportVariableText,
									`${
										isTestOwner
											? onlyFirstName
											: strings.hiddenname
									}`,
									`${totalCorrect}`,
									`${roundWithDecimals(zScore, 2)}`,
									`${testUserPronoun}`
							  );

					break;
				}
				default: {
					totalCorrectLabel = `${totalCorrect} av ${subtestQuestionCount}`;
					meanScore = subtestInfo['getPredictedScore'](
						foreign,
						testUser.ageGroup
					);
					standardDeviation = subtestInfo.norms[ageGroup]
						? subtestInfo.norms[ageGroup].standardDeviation
						: strings.notavailable;
					subtestExplanation = strings.formatString(
						subtestInfo.reportExplaination,
						<b>{meanScore}</b>,
						<b>{standardDeviation}</b>
					);

					formattedVariableText = strings.formatString(
						subtestInfo.reportVariableText,
						`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
						`${roundWithDecimals(totalCorrect, 2)}`,
						`${roundWithDecimals(zScore, 2)}`,
						`${testUserPronoun}`
					);
				}
			}
		} else if (type !== 'child') {
			if (subtestInfo.name === TEST_STEPS.SKRIVEHASTIGHET) {
				totalCorrectLabel = `${totalCorrect} falza ${strings.wordsperminute}`;
				meanScore = subtestInfo['getPredictedScore'](
					gender,
					foreign,
					testUser.education.youthGrade
				);
				if (
					meanScore === strings.notavailable &&
					type === 'adult-screening'
				) {
					meanScore = subtestInfo.norms['default'].average;
				}
				standardDeviation = subtestInfo.norms[ageGroup]
					? subtestInfo.norms[ageGroup].standardDeviation
					: strings.notavailable;
				if (
					standardDeviation === strings.notavailable &&
					type === 'adult-screening'
				) {
					standardDeviation =
						subtestInfo.norms['default'].standardDeviation;
				}
				subtestExplanation = strings.formatString(
					subtestInfo.reportExplaination,
					<b>{meanScore}</b>,
					<b>{standardDeviation}</b>
				);
				formattedVariableText = strings.formatString(
					subtestInfo.reportVariableText,
					`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
					`${totalCorrect}`,
					`${roundWithDecimals(zScore, 2)}`,
					`${testUserPronoun}`
				);
			} else {
				totalCorrectLabel = `${totalCorrect} av ${subtestQuestionCount}`;
				meanScore = subtestInfo['getPredictedScore'](
					gender,
					foreign,
					testUser.education.youthGrade
				);
				if (
					meanScore === strings.notavailable &&
					type === 'adult-screening'
				) {
					meanScore = subtestInfo.norms['default'].average;
				}
				standardDeviation = subtestInfo.norms[ageGroup]
					? subtestInfo.norms[ageGroup].standardDeviation
					: strings.notavailable;
				if (
					standardDeviation === strings.notavailable &&
					type === 'adult-screening'
				) {
					standardDeviation =
						subtestInfo.norms['default'].standardDeviation;
				}
				subtestExplanation = strings.formatString(
					subtestInfo.reportExplaination,
					<b>{meanScore}</b>,
					<b>{standardDeviation}</b>
				);
				formattedVariableText = strings.formatString(
					subtestInfo.reportVariableText,
					`${isTestOwner ? onlyFirstName : strings.hiddenname}`,
					`${totalCorrect}`,
					`${roundWithDecimals(zScore, 2)}`,
					`${testUserPronoun}`
				);
			}
		}

		const testZScorePercentage = (zScore + Z_SCORE_OFFSET) / Z_SCORE_MAX;
		const testZChartBarLeft =
			zScoreImageLabelWidth + testZScorePercentage * zScoreChartWidth;

		return (
			<div className="sub-test-Report" key={id}>
				<br />
				<div style={{paddingLeft: '10px'}}>
					<h3
						className="sub-test-Report__name"
						style={{color: '#0f4256', marginLeft: '40px'}}
					>
						{`${this.props.order}) ${subtestLabel}`}
					</h3>
					<div className="sub-test-report__scores">
						<div className="sub-test-Report">
							<p className="sub-test-Report__heading">
								{subTestReportHeading}
							</p>
							<p>{subtestExplanation}</p>

							<p>
								{formattedVariableText}
								<span>
									{zScore <= -1 && (
										<span className="lit-indented">
											<span className="lit-indented__under">
												{' under '}
											</span>
											{strings.averageForGrade}
										</span>
									)}
									{zScore >= 1 && (
										<span className="lit-indented">
											<span className="lit-indented__over">
												{' over '}
											</span>
											{strings.averageForGrade}
										</span>
									)}
									{zScore < 1 &&
										zScore > -1 &&
										ageGroup != 0 && (
											<span className="lit-indented">
												<span className="lit-indented__in">
													{' inom '}
												</span>
												{strings.averageForGrade}
											</span>
										)}
									{zScore < 1 &&
										zScore > -1 &&
										ageGroup == 0 &&
										id == 6 && <span>{''}</span>}
								</span>
							</p>
							<div className="sub-test-Report__margin"></div>
						</div>
						{this.props.children}
					</div>
				</div>
				{[2, 4, 6, '2', '4'].includes(this.props.order) && (
					<span
						className="mynewpage"
						style={{pageBreakAfter: 'always', display: 'block'}}
					></span>
				)}
			</div>
		);
	}
}

export default Subtest;
