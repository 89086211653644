import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './styles.scss';
import Logo from 'assets/dysmate-logo-hvit-small.png';

import {initAuthAction} from 'actions/index';

import mapFormElementsToNameValues from 'utils/mapFormElementsToNameValues';

import getRollString from 'api/getRollString';
import strings from 'utils/strings';
import Sidebar from 'components/Sidebar';

class Enrollment extends React.Component {
	constructor() {
		super();
		this.state = {
			isCreatingNewUser: true,
			hasSentEmail: false,
			createUserError: null,
		};
		this.handleCreateNewUserClick = this.handleCreateNewUserClick.bind(
			this
		);
		this.updateRegisterBoxHeight = this.updateRegisterBoxHeight.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount() {
		setTimeout(this.updateRegisterBoxHeight, 1000);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.error !== this.props.error) {
			this.setState(
				{
					isCreatingNewUser: true,
				},
				this.updateRegisterBoxHeight
			);
		}
	}

	updateRegisterBoxHeight() {
		const dimensions = this.refs.registerBox.getBoundingClientRect();
		this.setState({
			registerBoxHeight: dimensions.height,
		});
	}

	handleFormSubmit(event) {
		event.preventDefault();

		const form = event.target;

		const adminInfo = mapFormElementsToNameValues(form.elements);

		getRollString(adminInfo)
			.then(() => {
				this.setState(
					{
						hasSentEmail: true,
						isCreatingNewUser: false,
					},
					this.updateRegisterBoxHeight
				);
			})
			.catch((response) => {
				response.json().then((error) => {
					if (error.error === 'already registered') {
						if (error.hasOwnProperty('methods')) {
							this.setState(
								{
									createUserError: strings.formatString(
										strings.message_user_already_registered,
										error.methods[0],
										<a
											target="_self"
											rel="noopener noreferrer"
											href="https://admin.literate.no/"
										>
											<u>
												<i>her</i>
											</u>
										</a>
									),
								},
								this.updateRegisterBoxHeight
							);
						} else {
							this.setState(
								{
									createUserError: strings.formatString(
										strings.message_try_loggin_in,
										<a
											target="_self"
											rel="noopener noreferrer"
											href="https://admin.literate.no/"
										>
											<u>
												<i>her</i>
											</u>
										</a>
									),
								},
								this.updateRegisterBoxHeight
							);
						}
					} else if (error.error === 'not found') {
						this.setState(
							{
								createUserError: strings.message_user_not_found,
							},
							this.updateRegisterBoxHeight
						);
					} else if (error.error === 'trial expired') {
						this.setState(
							{
								createUserError:
									strings.message_trial_period_expired,
							},
							this.updateRegisterBoxHeight
						);
					} else if (error.error === 'not approved') {
						this.setState(
							{
								createUserError:
									strings.message_not_yet_approved,
							},
							this.updateRegisterBoxHeight
						);
					}
				});
			});
	}

	handleCreateNewUserClick() {
		this.setState(
			{
				isCreatingNewUser: !this.state.isCreatingNewUser,
			},
			this.updateRegisterBoxHeight
		);
	}

	render() {
		const {
			registerBoxHeight,
			isCreatingNewUser,
			hasSentEmail,
			createUserError,
		} = this.state;

		const {error} = this.props;
		const isLoginPage = Boolean(
			window.location.pathname === '/enrollment' || '/'
		);

		return (
			<div>
				<div className="lit-registration-view">
					<Sidebar isLoginPage={isLoginPage} />
					<div className="lit-view__content">
						<div className="lit-login">
							{
								<div
									className="lit-login__wrapper lit-login__wrapper--dark"
									ref="registerBox"
								>
									<div
										className="lit-register__bg bg-primary"
										style={{
											height: registerBoxHeight,
											backgroundColor: 'white',
											borderRadius: '25px',
										}}
									/>
									<div className="lit-register__fg">
										{!createUserError && (
											<h1
												className="lit-login__header"
												style={{color: 'orange'}}
											>
												{hasSentEmail
													? strings.success
													: 'Hej!'}
											</h1>
										)}
										{createUserError && (
											<h1 className="lit-login__header">
												{strings.errorhasoccured}
											</h1>
										)}
										{!createUserError && !hasSentEmail && (
											<span className="lit-login__description">
												{strings.formatString(
													strings.message_first_login,
													<span>
														<i>admin.literate.no</i>
													</span>
												)}
											</span>
										)}
										{!createUserError && !hasSentEmail && (
											<p className="lit-login__description">
												{
													strings.message_initial_approval
												}
											</p>
										)}

										{!createUserError && !hasSentEmail && (
											<p className="lit-login__description">
												{strings.message_provide_email}
											</p>
										)}
										{!createUserError && hasSentEmail && (
											<p className="lit-login__description">
												{
													strings.info_registration_eamil_sent
												}
											</p>
										)}
										{createUserError && (
											<p className="lit-login__description">
												{createUserError}
											</p>
										)}
										{!hasSentEmail && !isCreatingNewUser && (
											<div className="lit-login__action-wrapper">
												<button
													className="lit-btn bg-white"
													onClick={
														this
															.handleCreateNewUserClick
													}
												>
													{strings.createnewuser}
												</button>
											</div>
										)}
										{!hasSentEmail &&
											isCreatingNewUser &&
											!createUserError && (
												<div className="lit-register__expanded">
													<form
														className="lit-login__form"
														onSubmit={
															this
																.handleFormSubmit
														}
													>
														<label
															className="lit-login__form__label"
															htmlFor="newUser-email"
														>
															{strings.email}
														</label>
														<input
															autoFocus
															className="lit-login__form__input"
															id="newUser-email"
															name="email"
															// placeholder="din@epost.no"
															required
															type="email"
														/>
														<br />

														<button className="lit-btn bg-tertiary">
															{strings.send}
														</button>
													</form>
												</div>
											)}
									</div>
								</div>
							}
							{error && (
								<p>
									<strong>{error}</strong>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Enrollment.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
