import {ENDPOINT_URL} from 'constants/index';
const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function addAdminCustom(testKey) {
	return fetch(`${ENDPOINT_URL}/customregistration`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body: JSON.stringify({testKey}),
	}).then((response) => {
		if (response.ok) {
			return response;
		}

		return Promise.reject(response);
	});
}
