import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './styles.scss';
import Logo from 'assets/dysmate-logo-hvit-small.png';

import {initAuthAction} from 'actions/index';

import mapFormElementsToNameValues from 'utils/mapFormElementsToNameValues';

import newAdmin from 'api/newAdmin';
import strings from 'utils/strings';
import Sidebar from 'components/Sidebar';

class Registration extends React.Component {
	constructor() {
		super();
		this.state = {
			isCreatingNewUser: false,
			hasCreatedUser: false,
			createUserError: null,
		};
		this.handleCreateNewUserClick = this.handleCreateNewUserClick.bind(
			this
		);
		this.updateRegisterBoxHeight = this.updateRegisterBoxHeight.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount() {
		setTimeout(this.updateRegisterBoxHeight, 1000);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.error !== this.props.error) {
			this.setState(
				{
					isCreatingNewUser: true,
				},
				this.updateRegisterBoxHeight
			);
		}
	}

	updateRegisterBoxHeight() {
		const dimensions = this.refs.registerBox.getBoundingClientRect();
		this.setState({
			registerBoxHeight: dimensions.height,
		});
	}

	handleFormSubmit(event) {
		event.preventDefault();

		const form = event.target;

		const adminInfo = mapFormElementsToNameValues(form.elements);
		newAdmin(adminInfo)
			.then(() => {
				this.setState(
					{
						hasCreatedUser: true,
						isCreatingNewUser: false,
					},
					this.updateRegisterBoxHeight
				);
			})
			.catch((response) => {
				response.json().then((error) => {
					this.setState(
						{
							createUserError: error.error,
						},
						this.updateRegisterBoxHeight
					);
				});
			});
	}

	handleCreateNewUserClick() {
		this.setState(
			{
				isCreatingNewUser: !this.state.isCreatingNewUser,
			},
			this.updateRegisterBoxHeight
		);
	}

	render() {
		const {
			registerBoxHeight,
			isCreatingNewUser,
			hasCreatedUser,
			createUserError,
		} = this.state;

		const {error} = this.props;
		const isLoginPage = Boolean(
			window.location.pathname === '/register' || '/'
		);

		return (
			<div className="lit-login-page">
				<div className="lit-registration-view">
					<Sidebar isLoginPage={isLoginPage} />

					<div className="lit-view__content">
						<div className="lit-login">
							{
								<div
									className="lit-login__wrapper lit-login__wrapper--dark"
									ref="registerBox"
								>
									<div
										className="lit-register__bg"
										style={{
											height: registerBoxHeight,
											borderRadius: '25px',
										}}
									/>
									<div className="lit-register__fg">
										{!createUserError && (
											<h1 className="lit-login__header">
												{hasCreatedUser
													? strings.success
													: strings.newuser}
											</h1>
										)}
										{createUserError && (
											<h1 className="lit-login__header">
												{strings.errorhasoccured}
											</h1>
										)}
										{!createUserError &&
											!hasCreatedUser && (
												<p className="lit-login__description">
													{
														strings.message_change_loginmethod
													}
												</p>
											)}
										{!createUserError &&
											!hasCreatedUser && (
												<p className="lit-login__description">
													{
														strings.info_for_professional_with_either_completed_screening_test
													}
												</p>
											)}
										{!createUserError && hasCreatedUser && (
											<p className="lit-login__description">
												{
													strings.info_prerequisites_to_obtain_license
												}
											</p>
										)}
										{createUserError && (
											<p className="lit-login__description">
												En testadministratör med denna
												e-postadress finns redan. Försök
												att logga in istället.
											</p>
										)}
										{!hasCreatedUser && !isCreatingNewUser && (
											<div className="lit-login__action-wrapper">
												<button
													className="lit-btn bg-tertiary"
													onClick={
														this
															.handleCreateNewUserClick
													}
												>
													{strings.createnewuser}
												</button>
											</div>
										)}
										{!hasCreatedUser &&
											isCreatingNewUser &&
											!createUserError && (
												<div className="lit-register__expanded">
													<form
														className="lit-login__form"
														onSubmit={
															this
																.handleFormSubmit
														}
													>
														<label
															className="lit-login__form__label"
															htmlFor="newUser-email"
														>
															{strings.email}
														</label>
														<input
															autoFocus
															className="lit-login__form__input"
															id="newUser-email"
															name="email"
															// placeholder="din@epost.no"
															required
															type="email"
														/>
														<br />
														<label
															className="lit-login__form__label"
															htmlFor="newUser-organization"
														>
															{
																strings.shcool_or_company
															}
														</label>
														<input
															className="lit-login__form__input"
															id="newUser-organization"
															name="organization"
															// placeholder="Kongsbakken VGS"
															required
															type="text"
														/>
														<br />

														<label
															className="lit-login__form__label"
															htmlFor="newUser-phone"
														>
															{strings.telephone}
														</label>
														<input
															className="lit-login__form__input"
															id="newUser-phone"
															name="phone"
															// placeholder="+47 98765432"
															required
															type="tel"
														/>
														<button className="lit-btn lit-login__btn bg-primary">
															{
																strings.createnewuser
															}
														</button>
													</form>
												</div>
											)}
									</div>
								</div>
							}
							{error && (
								<p>
									<strong>{error}</strong>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Registration.propTypes = {
	error: PropTypes.string.isRequired,
	iframeUrl: PropTypes.string.isRequired,
	initAuth: PropTypes.func.isRequired,
	isInit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
