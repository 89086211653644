export default function getAgeLabel(age) {
	console.log('age ', Boolean(age));

	if (age) {
		const monthsLabel = age.months ? ` og ${age.months} mnd` : '';
		return `${age.years} år${monthsLabel}`;
	}

	return null;
}
