import {ENDPOINT_URL} from 'constants/index';

import getFetchOptions from 'utils/getFetchOptions';

export default function newAdmin(adminInfo) {
	adminInfo.origin = 'se';
	return fetch(
		`${ENDPOINT_URL}/register`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(adminInfo),
		})
	).then((response) => {
		if (response.ok) {
			return response;
		}

		return Promise.reject(response);
	});
}
