import {ENDPOINT_URL} from 'constants/index';
import {apiErrorHandling} from 'utils/apiUtils';

import getFetchOptions from 'utils/getFetchOptions';

export default function distributeKey(key, recipient, lang) {
	return fetch(
		`${ENDPOINT_URL}/test/distribute`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({
				key,
				recipient,
				lang,
			}),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			console.log(err);
			return Promise.reject(apiErrorHandling(err));
		});
}
