import React from 'react';
import '../../TestView/styles.scss';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import strings from 'utils/strings';
import {Link} from 'react-router-dom';
import handBookPdf from 'assets/DysmateHåndbok_v1.1_compressed.pdf';
import {
	CHILD_SCRNG_TEST_STEP_IDS,
	YOUTH_TEST_STEP_IDS,
} from '../../../constants';
import {getPronoun} from 'utils/getPronouns';
import {formateTestName} from 'utils/formatTestName';

const TESTS_REQUIRED_TO_PASS = 3;

class Assessment extends React.Component {
	constructor(props) {
		super(props);
	}

	// eslint-disable-next-line complexity
	render() {
		const {testResult, completeName, firstName} = this.props;
		const subtestsLength = testResult.subtests.length;
		const allLowZscores = {};

		let zScoreLowCount = 0;
		let writeUpText;
		let clearlyBelowZScoreCount = 0;
		let significantlyBelowZScoreCount = 0;
		let withInZScoreCount = 0;
		let clearlyAboveZScoreCount = 0;
		let testType;
		let stringForLowScoreInTwoSubtest;

		if (testResult.type === 'child-screening') {
			testType = CHILD_SCRNG_TEST_STEP_IDS;
			stringForLowScoreInTwoSubtest = strings.formatString(
				strings.localizedwriteuptextforLowScoreOfBetyledeWithOneOtherSubTest,
				`${getPronoun(testResult.testUser.gender)}`
			);
		} else {
			testType = YOUTH_TEST_STEP_IDS;
			stringForLowScoreInTwoSubtest = strings.formatString(
				strings.localizedwriteuptextforLowScoreOfOrdiktatWithOneOtherSubTest,
				`${firstName}`
			);
		}

		const findTestResult = testResult.subtests?.find((subtest) => {
			let subTestType;

			if (testType === CHILD_SCRNG_TEST_STEP_IDS) {
				subTestType = CHILD_SCRNG_TEST_STEP_IDS.BYTTELYDTESTEN;
			} else {
				subTestType = YOUTH_TEST_STEP_IDS.ORDDIKTAT;
			}
			return subtest.id === subTestType;
		});

		testResult.subtests.forEach((subtest) => {
			if (subtest) {
				const {id, zScore} = subtest;
				if (zScore < -1) {
					zScoreLowCount++;
					allLowZscores[id] = zScore;
				}
				if (zScore > -2.0 && zScore < -1.01) {
					clearlyBelowZScoreCount++;
				} else if (zScore < -2.01) {
					significantlyBelowZScoreCount++;
				} else if (zScore > -1.0 && zScore < 1.0) {
					withInZScoreCount++;
				} else if (zScore > 1.01) {
					clearlyAboveZScoreCount++;
				}
			}
		});

		//maps test names with Zscores

		const allLowZscoresTestsWithNames = {};
		for (const [key, value] of Object.entries(testType)) {
			if (allLowZscores[value] !== undefined) {
				allLowZscoresTestsWithNames[key] = allLowZscores[value];
			}
		}
		const keys = Object.keys(allLowZscoresTestsWithNames);

		//if the the test is incomplete
		if (testResult.completedAt === null) {
			writeUpText = strings.formatString(
				strings.localizedwriteuptextforIncompletedTest,
				`${completeName}`
			);
		} else if (
			testResult.type !== 'child-screening' &&
			zScoreLowCount === 2 &&
			allLowZscoresTestsWithNames.hasOwnProperty('ORDFORSTÅELSE') &&
			allLowZscoresTestsWithNames.hasOwnProperty('LESEPRØVEN')
		) {
			writeUpText = strings.formatString(
				strings.localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelse,
				`${completeName}`,
				clearlyBelowZScoreCount && !significantlyBelowZScoreCount
					? `klart under på ${clearlyBelowZScoreCount} av deltestene`
					: '',
				significantlyBelowZScoreCount && !clearlyBelowZScoreCount
					? `betydligt under på ${significantlyBelowZScoreCount} av deltestene`
					: '',
				clearlyBelowZScoreCount && significantlyBelowZScoreCount
					? `klart under på ${clearlyBelowZScoreCount} av deltestene och betydligt under på ${significantlyBelowZScoreCount} av deltestene `
					: '',
				withInZScoreCount > 0 || clearlyAboveZScoreCount > 0
					? `På de resterende deltestene ligger ${firstName} inom eller klart över genomsnittsområdet.`
					: '',
				`${getPronoun(testResult.testUser.gender)}`,
				<Link
					style={{color: 'blue'}}
					to={handBookPdf}
					target="_blank"
					rel="noopener noreferrer"
				>
					Dysmate Håndbok
				</Link>
			);
		} else if (
			findTestResult?.zScore <= -1 ||
			zScoreLowCount >= TESTS_REQUIRED_TO_PASS
		) {
			//if the zscore are low in betelid test for child and ordiktat for youth and adult (only implemented for special case)
			switch (true) {
				//if only one tests have low zscores (betelid or ordiktat)
				case zScoreLowCount === 1:
					writeUpText = strings.formatString(
						strings.localizedwriteuptextforNormalresults,
						`${completeName}`,
						withInZScoreCount && !clearlyAboveZScoreCount
							? `inom`
							: '',
						clearlyAboveZScoreCount && !withInZScoreCount
							? `klart över`
							: '',
						withInZScoreCount && clearlyAboveZScoreCount
							? `inom och klart över `
							: '',
						`${withInZScoreCount + clearlyAboveZScoreCount}`,
						`${getPronoun(testResult.testUser.gender)}`
					);
					break;
				//if only two tests have low zscores
				case zScoreLowCount === 2:
					writeUpText = strings.formatString(
						strings.localizedwriteuptextforOneOtherSubTest,
						`${completeName}`,
						clearlyBelowZScoreCount &&
							!significantlyBelowZScoreCount
							? `klart under på ${clearlyBelowZScoreCount} av deltestene`
							: '',
						significantlyBelowZScoreCount &&
							!clearlyBelowZScoreCount
							? `betydligt under på ${significantlyBelowZScoreCount} av deltestene`
							: '',
						clearlyBelowZScoreCount && significantlyBelowZScoreCount
							? `klart under på ${clearlyBelowZScoreCount} av deltestene och betydligt under på ${significantlyBelowZScoreCount} av deltestene `
							: '',
						withInZScoreCount > 0 || clearlyAboveZScoreCount > 0
							? `På de resterende deltestene ligger ${firstName} inom eller klart över genomsnittsområdet.`
							: '',
						stringForLowScoreInTwoSubtest,
						<Link
							style={{color: 'blue'}}
							to={handBookPdf}
							target="_blank"
							rel="noopener noreferrer"
						>
							Dysmate Håndbok
						</Link>
					);
					break;
				//if only three tests have low zscores
				case zScoreLowCount === 3:
					writeUpText = strings.formatString(
						strings.localizedwriteuptextforTwoOtherSubTest,
						`${completeName}`,
						clearlyBelowZScoreCount &&
							!significantlyBelowZScoreCount
							? `klart under på ${clearlyBelowZScoreCount} av deltestene`
							: '',
						significantlyBelowZScoreCount &&
							!clearlyBelowZScoreCount
							? `betydligt under på ${significantlyBelowZScoreCount} av deltestene`
							: '',
						clearlyBelowZScoreCount && significantlyBelowZScoreCount
							? `klart under på ${clearlyBelowZScoreCount} av deltestene och betydligt under på ${significantlyBelowZScoreCount} av deltestene `
							: '',
						withInZScoreCount > 0 || clearlyAboveZScoreCount > 0
							? `På de resterende deltestene ligger ${firstName} inom eller klart över genomsnittsområdet.`
							: '',
						`${getPronoun(testResult.testUser.gender)}`,
						`${formateTestName(keys[0])}`,
						`${formateTestName(keys[1])}`,
						`${formateTestName(keys[2])}`,
						`${firstName}`,
						<Link
							style={{color: 'blue'}}
							to={handBookPdf}
							target="_blank"
							rel="noopener noreferrer"
						>
							Dysmate Håndbok
						</Link>
					);
					break;
				//if more than three tests have low zscores
				case zScoreLowCount > 3:
					if (
						zScoreLowCount === 6 ||
						zScoreLowCount === 5 ||
						zScoreLowCount === 4
					) {
						const lastElement = keys[keys.length - 1];
						writeUpText = strings.formatString(
							strings.localizedwriteuptextforThreeORMoreOtherSubTest,
							`${completeName}`,
							clearlyBelowZScoreCount &&
								!significantlyBelowZScoreCount
								? `klart under på ${clearlyBelowZScoreCount} av deltestene`
								: '',
							significantlyBelowZScoreCount &&
								!clearlyBelowZScoreCount
								? `betydligt under på ${significantlyBelowZScoreCount} av deltestene`
								: '',
							clearlyBelowZScoreCount &&
								significantlyBelowZScoreCount
								? `klart under på ${clearlyBelowZScoreCount} av deltestene och betydligt under på ${significantlyBelowZScoreCount} av deltestene `
								: '',
							withInZScoreCount > 0 || clearlyAboveZScoreCount > 0
								? `På de resterende deltestene ligger ${firstName} inom eller klart över genomsnittsområdet.`
								: '',
							`${getPronoun(testResult.testUser.gender)}`,
							`${formateTestName(keys[0])}`,
							`${formateTestName(keys[1])}`,
							`${formateTestName(keys[2])}`,
							// eslint-disable-next-line no-nested-ternary
							keys[3]
								? keys[3] === lastElement
									? `och ${formateTestName(keys[3])}`
									: `, ${formateTestName(keys[3])}`
								: '',
							// eslint-disable-next-line no-nested-ternary
							keys[4]
								? keys[4] === lastElement
									? `och ${formateTestName(keys[4])}`
									: `, ${formateTestName(keys[4])}`
								: '',
							// eslint-disable-next-line no-nested-ternary
							keys[5]
								? keys[5] === lastElement
									? `och ${formateTestName(keys[5])}`
									: `, ${formateTestName(keys[5])}`
								: '',
							<Link
								style={{color: 'blue'}}
								to={handBookPdf}
								target="_blank"
								rel="noopener noreferrer"
							>
								Dysmate Håndbok
							</Link>
						);
					}
					break;
			}
		} else {
			//if the zscores are in normal range
			writeUpText = strings.formatString(
				strings.localizedwriteuptextforNormalresults,
				`${completeName}`,
				withInZScoreCount && !clearlyAboveZScoreCount ? `inom` : '',
				clearlyAboveZScoreCount && !withInZScoreCount
					? `klart över`
					: '',
				withInZScoreCount && clearlyAboveZScoreCount
					? `inom och klart över `
					: '',
				`${withInZScoreCount + clearlyAboveZScoreCount}`,
				`${getPronoun(testResult.testUser.gender)}`
			);
		}
		return (
			<div className="sub-test__scores">
				<div className="width--half center">
					<h4 className="sub-test__heading big">
						{strings.assessment}
					</h4>
					<p>{writeUpText}</p>
				</div>
				<div className="width--half center">
					<p>{strings.textUnderEveryScreeningAssessment}</p>
				</div>
			</div>
		);
	}
}

Assessment.propTypes = {
	currentUser: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
